import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Row} from "react-bootstrap";
import {GetReportById} from "../../services/reports.service";
import moment from "moment";
import {REPORT_TYPES} from "../../utils/constants";

function ViewDetail(props) {
    const history = useHistory();
    const [data, setData] = useState({});

    useEffect(async () => {
        let id = props.match.params.id;
        await getReportById(id);

    }, []);

    const getReportById = async (id) => {
        await GetReportById(id).then(async (result) => {
            console.log("result --- ", result)
            if (result.status) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setData(
                            {
                                _id: dt._id || '-',
                                name: dt?.user?.fullName || '-',
                                email: dt?.user?.email || '-',
                                report_type: dt?.type?.name || '-',
                                module: dt?.module || '-',
                                report_content : (dt?.module === REPORT_TYPES.STREAMING)? dt?.post.name:dt?.post.title || '-',
                                created_at: moment(dt?.created_at).format("YYYY-MM-DD"),
                            }
                        )
                    }
                }
            } else {
                toast.error(result.data.data.message);
            }
        }).catch((error) => {
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast().error(error.data.message);
            }
        })
    }

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">Report Detail</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={8} md={7} lg={6} xl={7}>
                            <div className={"profileDetails"}>
                                <h4>REPORT DETAILS</h4>
                                <p><b>Report Type:</b>  {data?.report_type}</p>
                                <p><b>Report on:</b> {data?.module}</p>
                                <p><b>Content:</b> {data?.report_content}</p>
                                <p><b>Date Created On:</b>  {data?.created_at}</p>

                            </div>
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={6} xl={7}>
                            <div className={"profileDetails"}>
                                <h4>USER DETAILS</h4>
                                <p><b>Name:</b>  {data?.name}</p>
                                <p><b>Email:</b>  {data?.email}</p>
                            </div>
                        </Col>

                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewDetail