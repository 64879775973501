import React, {useEffect, useState} from 'react'
import swal from "sweetalert"
import {Link, useHistory} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap"
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons"
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {constants, customStyles} from "../../utils/constants";
import {DeleteUser, GetUsers} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import moment from "moment";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import {EmptyState} from "../../components/EmptyState";


function Users() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [tableData,setTableData] = useState({});

    const getUsers = async () => {
        setLoading(true)
        await GetUsers(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.users) {
                    const rowData = [];

                    // setPage(result?.data?.pagination?.current_page)
                    setTotalRows(result.data?.pagination?.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result.data.users.map((dt, index) => {
                        rowData.push({
                            sno: counter++,
                            _id:dt._id || '-',
                            fullName: dt.fullName || '-',
                            profileImage: dt.profileImage || constants.DEFAULTUSERIMAGE,
                            email: dt.email || '-',
                            created_at: moment(dt.createdAt).fromNow(),
                        });
                    })
                    setRows(rowData);
                    setTableData({
                        columns : columns ,
                        data : result?.data?.users

                    })
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }


    useEffect(() => {
        const fetchData = async () => {
            await getUsers();

        };

        fetchData();
    }, [keyword, page, perPage]);


    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteUser(id).then((result) => {
                    if (result.status) {
                        swal(result.data.message, {
                            icon: "success",
                        });
                        getUsers();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error?.response?.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        // console.log( data._id)
        history.push('/manage-users/edit-user/' + data._id, {
            data: data
        });
    }

    const userActions = (data) => {
        // console.log(data.status == "ACTIVE", data.status)
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onUserDelete(data._id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {

        const a = rowA.fullName.toLowerCase();
        const b = rowB.fullName.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };


    const columns = [
        {
            id: 'sno',
            name: 'ID',
            center: true,
            sortable: true,
            selector: rowData => rowData.sno,
            cellExport: rowData => rowData.sno
        },
        {
            id: 'fullName',
            name: 'Name',
            sortable: true,
            center: true,
            selector: rowData => rowData.fullName,
            sortField: 'name',
            sortFunction: caseInsensitiveNameSort,
            cellExport:  rowData => rowData.fullName
        },
        {
            id: 'image',
            name: 'Image',
            center: true,
            cell: (rowData) => <img src={rowData.profileImage} alt={rowData.fullName} style={{ width: '50px', height: '50px' }} />,
            selector: rowData => rowData.profileImage,
            cellExport: rowData => rowData.profileImage
        },
        {
            id: 'email',
            name: 'Email',
            center: true,
            sortable: true,
            selector: rowData => rowData.email,
            cellExport : rowData => rowData.email
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            sortable: true,
            selector: rowData => rowData.created_at,
            cellExport : rowData => rowData.created_at
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export: false
        },
    ];


    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Users</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*{rows && rows.length > 0 ? (*/}
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                        {/*) : ('')}*/}
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/manage-users/add-user"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                    <DataTableExtensions
                        columns={columns}
                        data={rows}
                        filter={false}
                        print={false}
                        exportHeaders
                        fileName='Users'
                    >
                        <DataTable
                            columns={columns}
                            data={rows}
                            progressPending={loading}
                            customStyles={customStyles}
                            selectableRows={false}
                            striped
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // paginationPerPage={perPage}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage) => {
                                setPerPage(currentRowsPerPage)
                            }}
                            progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                          height={'4'} width={'100%'} />}
                        />
                    </DataTableExtensions>
                )}
            </div>
        </div>
    )
}

export default Users
