import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FadeLoader} from "react-spinners";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {uploadToS3} from "../../utils/helpers";
import {GetAdvertisements} from "../../services/advertisements.service";
import Select from "react-select";
import {AddAdAssignToCreator, GetAdsByUser, GetUserDeleteById} from "../../services/adassigntocreator.service";

function EditUser(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [file, setFile] = useState(null)
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [defaultImage, setDefaultImage] = useState(false);
    const [storeAdvertisements, setStoreAdvertisements] = useState([]);
    const [selectedAd, setSelectedAd] = useState(null);
    const [videoAd, setVideoAd] = useState();
    const [userAd, setUser] = useState();
    const [adId, setAdId] = useState();

    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    useEffect(async () => {
        console.log("asd", props.match.params.id)
        let id = props.match.params.id;
        await getUserAd(id);
        await getAdvertisements();
        await getUserByid(id);
    }, [])


    const getUserAd = async (user_id) => {

        await GetAdsByUser(user_id).then((result) => {
            if (result.status && result.data) {
                setSelectedAd({
                    label: result?.data?.advertisements?.name,
                    value: result?.data?.advertisements?._id,
                });
            }else {
                setSelectedAd(null)
            }
        });
    };

    const getUserByid = async (id) => {
        await GetUserById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setValue("fullName", result.data?.fullName);
                    setValue("email", result.data?.email);
                    if(result.data?.profileImage){
                        setImage(result.data?.profileImage)
                        setIsUpload(true)
                    }
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getAdvertisements = async () => {
        await GetAdvertisements(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setStoreAdvertisements(result.data);
            }
        });
    };

    const selectedAdvertisement = async (item) => {
        let videoAd
        if(!item){
            setSelectedAd(null);
            setVideoAd({})
        }else {
            videoAd = storeAdvertisements.find((ad) => ad._id === item.value)
            setVideoAd(videoAd)
            setSelectedAd(item);
        }
        return videoAd;
    };


    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = async data => {

        if(isUploadOverWrite){
            if(file.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}

        setLoader(true)
        let imageUrl = null;

        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Image is required"})
            }

            let uploadImage = await uploadToS3(file.type,file.name,file,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            imageUrl=uploadImage;
        }else {
            imageUrl = image
        }

        let id = props.match.params.id;
        let dt = {
            fullName: data.fullName,
            email: data.email,
            profileImage: imageUrl,
        }
        if (data.password && data.password_confirmation) {
            dt.password = data.password;
            dt.password_confirmation = data.password_confirmation;
        }
        let AdData = {
            advertisements: videoAd,
            users: [props.match.params.id],
        };
        if(Object.keys(videoAd).length === 0){
            await GetUserDeleteById(props.match.params.id)
        }else {
            await AddAdAssignToCreator(AdData)
        }

        await UpdateUser(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success('User update successfully');
                history.push('/manage-users');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status === 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    };

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
            clearErrors('profileImage')
        }

    }

    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }

    return (
        <div className="EditUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit User</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={7}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 3 mb)</Form.Text> </Form.Label>
                                    <input
                                        {...register('profileImage')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.profileImage && <Form.Text
                                        className=" validationText hasError">{errors.profileImage.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register('fullName', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.fullName && <Form.Text
                                        className="text-muted validationText hasError">{errors.fullName.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Max length is 255"
                                            },
                                        })} type="email" readOnly/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password*</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            maxLength={25}
                                            minLength={8}
                                            {...register('password', {
                                                // required: {
                                                //     value: "required",
                                                //     message: "Password is required"
                                                // },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 25,
                                                    message: "Max length is 25"
                                                },
                                            })}
                                            onClick={() => setConfirmPass(!confirmPass)}
                                            type={pass ? "password" : "text"} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password*</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            disabled={confirmPass}
                                            maxLength={25}
                                            minLength={3}
                                            {...register('password_confirmation', {
                                                // required: {
                                                //     value: "required",
                                                //     message: "Confirm Password is required"
                                                // },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" disabled={confirmPass} onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className="d-block">
                                        Select advertisement
                                    </Form.Label>
                                    <Select
                                        options={storeAdvertisements && storeAdvertisements.map((e) => ({label: e.name,value: e._id,}))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        onChange={selectedAdvertisement}
                                        defaultValue={selectedAd}
                                        value={selectedAd}
                                    />
                                    {errors.advertisement && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.advertisement.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Update User"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditUser
