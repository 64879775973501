import {apiUrl} from '../utils/constants';
import {DELETE, GET, PATCH, POST} from './api.service.wrapper';


export const GetAdvertisements = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.advertisements, params);
    return response;
}

export const GetAdvertisementById = async (id) => {
    let response = '';
    response = await GET(apiUrl.advertisements + '/' + id);
    return response;
}

export const CheckDefaultAd = async (is_default) => {
    let params = {};

    params['is_default'] = is_default;

    const response = await GET(apiUrl.default_ad, params);
    return response;
}

export const AddAdvertisement = async (data) => {
    const response = await POST(apiUrl.advertisements, data);
    return response;
}

export const UpdateAdvertisement = async (id, data) => {
    const response = await PATCH(apiUrl.advertisements, id, data);
    return response;
}

export const DeleteAdvertisement = async (id) => {
    const response = await DELETE(apiUrl.advertisements, id);
    return response;
}
