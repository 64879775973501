import React, {useEffect, useState} from 'react'
import {Link, useHistory} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap"
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons"
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {customStyles} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";
import DataTableExtensions from "react-data-table-component-extensions";
import {EmptyState} from "../../components/EmptyState";
import {DeleteAdvertisement, GetAdvertisements} from "../../services/advertisements.service";

function Advertisements() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    const getAdvertisements = async () => {
        setLoading(true)
        await GetAdvertisements(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];

                    setTotalRows(result.data.pagination.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            sno: counter++,
                            _id: dt._id || '-',
                            name: dt.name || '-',
                            default: (dt.is_default)?'Yes':'No',
                            created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.data.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    useEffect(async () => {
        await getAdvertisements();
    }, [keyword, page, perPage])

    const onEdit = (data) => {
        history.push('/advertisements/edit-advertisement/' + data._id, {
            data: data
        });
    }

    const onDeleteRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this advertisement?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteAdvertisement(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getAdvertisements();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.data.message);
                    }
                })
            }
        });
    }


    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDeleteRecord(data._id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {

        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'sno',
            name: 'ID',
            sortable: true,
            center: true,
            selector: rowData => rowData.sno,
            cellExport: rowData => rowData.sno,
        },
        {
            id: 'name',
            name: 'Name',
            center: true,
            sortable: true,
            sortField: 'name',
            selector: rowData => rowData.name,
            sortFunction: caseInsensitiveNameSort,
            cellExport: rowData => rowData.name,
        },
        {
            id: 'default',
            name: 'default',
            center: true,
            sortable: true,
            sortField: 'default',
            selector: rowData => rowData.default,
            cellExport: rowData => rowData.default,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export: false
        },
    ];

    return (
        <div className={"usersPage"}>
            <h1 className="page-heading">Advertisements</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/advertisements/create-advertisement"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline /> Add New
                            </Button>

                        </Link>
                    </Col>

                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid" style={{"cursor":"pointer"}}>
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                    <DataTableExtensions
                        columns={columns}
                        data={rows}
                        filter={false}
                        print={false}
                        exportHeaders
                        fileName='Packages'
                    >
                        <DataTable
                            pagination
                            columns={columns}
                            data={rows}
                            persistTableHead
                            progressPending={loading}
                            customStyles={customStyles}
                            selectableRows={false}
                            striped
                            highlightOnHover

                            paginationServer
                            paginationTotalRows={totalRows}

                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage) => {
                                setPerPage(currentRowsPerPage)
                            }}
                            progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                          height={'4'} width={'100%'} />}
                        />
                    </DataTableExtensions>

                )}
            </div>
        </div>
    )
}

export default Advertisements
