import {apiUrl} from '../utils/constants';
import {DELETE, GET, PATCH, POST} from './api.service.wrapper';


export const GetAdAssignToCreators = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.ad_assign_to_creator, params);
    return response;
}

export const GetAdsByUser = async (user_id) => {
    let params = {};
    params['user_id'] = user_id;

    return await GET(apiUrl.get_user_ads, params);
}

export const GetUserDeleteById = async (user_id) => {
    let params = {};
    params['user_id'] = user_id;
    return await GET(apiUrl.get_user_ad_delete, params);
}

export const AddAdAssignToCreator = async (data) => {
    const response = await POST(apiUrl.ad_assign_to_creator, data);
    return response;
}

export const AdAssignToCategory = async (data) => {
    const response = await POST(apiUrl.ad_assign_to_category_videos, data);
    return response;
}


export const UpdateAdAssignToCreator = async (id, data) => {
    const response = await PATCH(apiUrl.ad_assign_to_creator, id, data);
    return response;
}

export const DeleteAdAssignToCreator = async (id) => {
    const response = await DELETE(apiUrl.ad_assign_to_creator, id);
    return response;
}
