import React, {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap';
import DashboardStat from "../../components/DashboardStat";
import {Link, useHistory} from "react-router-dom";
import ThemeChart from "../../components/Chart";
import Card from 'react-bootstrap/Card';


import {GetDashboardCounts, GetSubscriptionChartData} from "../../services/dashboard.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {toast} from "react-toastify";


function Dashboard() {
    let history = useHistory();
    const bottomDivRef = useRef(null);
    const [totalUsers, setTotalUsers] = useState("0");
    const [totalTransactions, setTotalTransactions] = useState("0");
    const [totalLiveEvents, setTotalLiveEvents] = useState("0");
    const [totalUpComingEvent, setTotalUpComingEvent] = useState("0");
    const [totalQueriesDataSets, setTotalQueriesDataSets] = useState({

    });
    const  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec']
    const[timePeriod, setTimePeriod] = useState({})

    const graphDataWithMonthNames = (graphData)=>{
      return  graphData.map(data => {
            const monthName = monthNames[data.month - 1]; // month index starts from 0
            return {
                ...data,
                monthName: monthName
            };
        })
    }

    const getSubscriptionChartData = async () => {
        await GetSubscriptionChartData().then((result)=>{
            if (result?.status) {
                if (result?.data) {
                    setTimePeriod({
                        previousYear: result?.data?.previousYear,
                        previousMonth: result?.data?.previousMonth,
                        currentYear: result?.data?.currentYear,
                        currentMonth: result?.data?.currentMonth})
                  const newGraphData =  graphDataWithMonthNames(result?.data?.graphData)


                    const monthNamesArray = newGraphData.map(data => data.monthName);


                    const querydata = {
                        labels: monthNamesArray || "",
                        datasets: [
                            {
                                label: 'Total Amount in $',
                                data: result?.data?.graphData?.map(data => data.count) || [],
                                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                borderColor: 'rgb(75, 192, 192)',
                                borderWidth: 1,
                            }
                        ],
                    };
                    setTotalQueriesDataSets(querydata)
                }
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }

    const getDashboardCounts = async () => {
        await GetDashboardCounts().then((result)=>{
            if (result.status) {
                if (result?.dashboard) {
                    let dashboard = result.dashboard
                    setTotalUsers(dashboard.users)
                    setTotalTransactions(dashboard.user_subcription)
                    setTotalLiveEvents(dashboard.live_event_count)
                    setTotalUpComingEvent(dashboard.upcoming_event_count)
                }
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }

    useEffect(async () => {
        await getDashboardCounts()
        await getSubscriptionChartData()

    }, [])

    return (
        <div className='dashboard'>
            <h1 className="page-heading">Dashboard</h1>
            <hr />
            <Row className={"mb-4 mt-2 "}>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/manage-users"} className={"linkStats"}>
                        <DashboardStat title={"Total Registered Accounts"} countMain={totalUsers} />
                    </Link>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/transactions"} className={"linkStats"}>
                        <DashboardStat title={"Total Transactions"} countMain={totalTransactions} />
                    </Link>
                </Col>

                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/"} className={"linkStats"}>
                        <DashboardStat title={"Total Live Events"} countMain={totalLiveEvents} />
                    </Link>
                </Col>

                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={"mb-4"}>
                    <Link to={"/"} className={"linkStats"}>
                        <DashboardStat title={"Upcoming events"} countMain={totalUpComingEvent} />
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-4"}>
                    <Card>
                        <Card.Body>
                        <Card.Title>Monthly Subscription Report {" "}{monthNames[timePeriod.previousMonth - 1]}-{timePeriod.previousYear}{" "}to{" "}{monthNames[timePeriod.currentMonth - 1]}-{timePeriod.currentYear}</Card.Title>
                        <ThemeChart datasets={totalQueriesDataSets}  />
                    </Card.Body>
                </Card>
                </Col>
            </Row>

        </div>
    )
}

export default Dashboard
