import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from 'draft-js';
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {AddCategory} from "../../services/categories.service";
import Select from "react-select";
import {GetAdvertisements} from "../../services/advertisements.service";


function AddNewCategory() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [advertisements, setAdvertisements] = useState([]);
    const [videoAd, setVideoAd] = useState();
    const [advertisementId, setAdvertisementId] = useState();
    const [selectedAd, setSelectedAd] = useState(null);

    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        getAdvertisements();
    }, [])

    const getAdvertisements = async () => {
        await GetAdvertisements(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setAdvertisements(result.data);
            }
        });
    };

    const selectedAdvertisement = async (item) => {
        let videoAd
        if(!item){
            setSelectedAd(null);
            setVideoAd({})
            setAdvertisementId("")
        }else{
            videoAd = advertisements.find((ad) => ad._id === item.value)
            setSelectedAd({
                label:videoAd.name,
                value:videoAd._id
            })
            setVideoAd({
                _id:videoAd._id,
                name:videoAd.name
            })

            setAdvertisementId(item.value)
            clearErrors("advertisement");
        }
        return videoAd;
    };


    const onSubmit = async data => {
        setLoader(true)

        let dt = {
            name: data.name,
        }

        if(advertisementId !== ""){
            dt.advertisement_id = advertisementId
            dt.advertisement = videoAd
        }
        await AddCategory(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/categories');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error?.response?.status === 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    return (
        <div className="AddPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add Category</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        {...register('name',{
                                            required: 'Name is required',
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: 'Title cannot exceed 20 characters',
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className="d-block">
                                        Select advertisement
                                    </Form.Label>
                                    <Select
                                        options={advertisements && advertisements.map((e) => ({label: e.name,value: e._id,}))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        onChange={selectedAdvertisement}
                                        defaultValue={selectedAd}
                                        value={selectedAd}
                                    />
                                    {errors.advertisement && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.advertisement.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Add Category"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewCategory;