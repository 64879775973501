import {apiUrl} from '../utils/constants';
import {DELETE, GET, PATCH, POST} from './api.service.wrapper';


export const GetReportedPosts = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['is_admin'] = true;
    params['is_reported'] = true;

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.reported_posts, params);
    return response;
}

export const GetPostById = async (id, params) => {
    let response = '';
    response = await GET(apiUrl.reported_posts + '/' + id, params);
    return response;
}

export const AddReportedPost = async (data) => {
    const response = await POST(apiUrl.reported_posts, data);
    return response;
}

export const UpdateReportedPost = async (id, data) => {
    const response = await PATCH(apiUrl.reported_posts+'/admin/report-post', id, data);
    return response;
}

export const DeleteReportedPost = async (id) => {
    const response = await DELETE(apiUrl.reported_posts, id);
    return response;
}
