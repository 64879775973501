import React, {useRef, useState} from 'react'
import {Button, Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import {FadeLoader} from "react-spinners";
import {uploadToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {userConstantData} from "../../utils/constants";
import {CreateUser} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";


function AddNewUser(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [confPass, ToggleConfPass] = useState(true);
    const [file, setFile] = useState(null)
    const [isUpload, setIsUpload] = useState(false)
    const [image, setImage] = useState()
    const [confirmPass, setConfirmPass] = useState(true);
    const [loader, setLoader] = useState(false);
    const { register, watch, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format


    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = async data => {

        // console.log(file.type, file)
        if(file){
            clearErrors('profileImage')
        }else{
            setError('profileImage',{message:"Image is required"})
        }
        if(file.type.startsWith('image/')){
            clearErrors('profileImage')
        }else{
            setError('profileImage',{message:'Invalid file type'})
            return false;
        }

        setLoader(true)
        let uploadImage = await uploadToS3(file.type,file.name,file,'users');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        let dt = {
            fullName: data.fullName,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            profileImage: uploadImage,
        }

        const mergedData = { ...dt, ...userConstantData };
        // console.log("data: ",dt)

        await CreateUser(mergedData).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success('registered user have been sent a password email');
                history.push('/manage-users');
            } else {
                if (data.status === false && data.message.includes('User already exists')) {
                    setError('email',{message:'This email is already registered.'});
                } else {
                    setError('email',{message:''});
                }
                setLoader(false)

                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.message.includes('User already exists')) {
                setError('email',{message:'This email is already registered.'});
            }
            else {
                setError('email',{message:''});
                return toast.error(error.message);
            }
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
        })
    };

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        const fileType = fileInput[0]?.type;

        if(fileUrl){
            clearErrors('profileImage')
        }else{
            setError('profileImage',{message:"Image is required"})
        }

    }


    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }
    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Create User</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={7}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 3 mb)</Form.Text> </Form.Label>
                                    <input
                                        {...register('profileImage')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.profileImage && <Form.Text
                                        className=" validationText hasError">{errors.profileImage.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={5}
                                        {...register('fullName', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "Min length is 5"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.fullName && <Form.Text
                                        className="text-muted validationText hasError">{errors.fullName.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Max length is 255"
                                            },
                                        })} type="email" />
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password*</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            maxLength={255}
                                            minLength={8}
                                            {...register('password', {
                                                required: {
                                                    value: "required",
                                                    message: "Password is required"
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            onBlur={() => setConfirmPass(!confirmPass)}
                                            type={pass ? "password" : "text"} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password*</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            disabled={confirmPass}
                                            maxLength={25}
                                            minLength={3}
                                            {...register('password_confirmation', {
                                                required: {
                                                    value: "required",
                                                    message: "Confirm Password is required"
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" disabled={confirmPass} onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-sm btn-green-theme mt-3"}
                                           value={"Create User"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AddNewUser
