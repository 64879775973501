import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {constants, customStyles} from "../../utils/constants";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";
import {Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap";
import {MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {DeleteTransaction, GetTransactions} from "../../services/transaction.service";
import moment from "moment";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import {EmptyState} from "../../components/EmptyState";

function Transactions(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getTransactions();
    }, [keyword, page, perPage])

    const getTransactions = async () => {
        setLoading(true)
        // let user_id = JSON.parse(localStorage.getItem('USER_DATA'))._id

        await GetTransactions(keyword, page, perPage).then((result) => {
            console.log(result,"RESULT")
            // if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setTotalRows(result.pagination?.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: counter++,
                            _id:dt._id || '-',
                            user_name: dt?.user?.fullName|| '-',
                            email: dt?.user?.email || '-',
                            package_name: dt.package.name || '-',
                            transaction_id: dt.package_id || '-',
                            duration: dt?.duration,
                            price: dt?.amount,
                            created_at: moment(dt?.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            // }
            else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            console.log(error.status,"ERROR")
            console.log("Messge", error)
            setLoading(false)
            if (error.status == false) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error);
            }
        })
    }



    const onTransactionDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this transaction?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteTransaction(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getTransactions();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        console.log( "ID:", data._id)
        history.push('user-subscriptions/' + data._id, {
            data: data
        });
    }

    const viewTransaction = (data) => {
        history.push('/transactions/view-detail/' + data._id);
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="start" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => viewTransaction(data)}>View Detail</Dropdown.Item>
                {/*<Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>*/}
                {/*<Dropdown.Item as="button" onClick={() => onTransactionDelete(data._id)}>Delete</Dropdown.Item>*/}
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '60px',
            center: true,
            selector: rowData => rowData.id,
            cellExport: rowData => rowData.id
        },
        {
            id: 'user_name',
            name: 'User',
            sortable: false,
            selector: rowData => rowData.user_name,
            cellExport: rowData => rowData.user_name
        },
        {
            id: 'email',
            name: 'Email',
            selector: rowData => rowData.email,
            cellExport: rowData => rowData.email
        },
        {
            id: 'package_name',
            name: 'Subscription',
            center: true,
            width: '120px',
            selector: rowData => rowData.package_name,
            cellExport: rowData => rowData.package_name
        },
        {
            id: 'duration',
            name: 'Duration',
            center: true,
            width: '50px',
            selector: rowData => rowData.duration,
            cellExport: rowData => rowData.duration
        },
        {
            id: 'price',
            name: 'Price ($)',
            center: true,
            width: '50px',
            selector: rowData => rowData.price,
            cellExport: rowData => rowData.price
        },
        {
            id: 'created_at ',
            name: 'Created At ',
            center: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export : false
        },
    ];

    return (
        <div className={"transactionsPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Transactions</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*{rows && rows.length > 0 ? (*/}
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                        {/*) : ('')}*/}
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>

                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                <DataTableExtensions
                    columns={columns}
                    data={rows}
                    filter={false}
                    print={false}
                    exportHeaders
                    fileName='Transactions'
                >
                <div className="theme-dataGrid products-dataGrid">

                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    persistTableHead
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover

                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
                </DataTableExtensions>
                )}
            </div>
        </div>
    )
}

export default Transactions;