import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EmptyLocalStorage } from '../../services/auth/auth.service';
import { Col, Form, Row } from 'react-bootstrap';
import { FadeLoader } from 'react-spinners';
import {GetPackageById, CreatePackage} from "../../services/packages.service";
import {CHARACTER_ARRAY_FOR_NUMBER_INPUT, EXCLUDE_NUMBER_INPUT} from "../../utils/constants";


function AddPackage(props){
    let history = useHistory();
    const {register, watch,setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "all",
    });
    const [loader, setLoader] = useState(false);
    const[criteria,setCriteria] = useState(true)

    const onSubmit = async data => {
        console.log(data,"form data")
        let id = props.match.params.id;
        setLoader(true)


        let dt = {
            duration: JSON.parse(data.duration),
            price: JSON.parse(data.price),
            name: data.name,
            description: data.description,
            criteria : {
                community : JSON.parse(criteria)
            }
        }

        console.log(dt,"DATA")

        await CreatePackage(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success("success");
                history.push('/packages');
            } else {
                setLoader(false)
                toast.error("error");
            }
        }).catch((error) => {
            console.log(error,"ERROR")
            setLoader(false)
            if (error.status == false) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error("error");
            }
        })
    };

    return (
        <div className="EditBg">
            <h3 className="page-heading">Add Package</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
                                                message:
                                                    "Enter full name containing alphabets only"
                                            },
                                        })}

                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Price*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Price in $"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('price', {
                                            required: {
                                                value: "required",
                                                message: "Price is required"
                                            },
                                            min: {
                                                value: 1,
                                                message: "Min value is $1"
                                            },
                                            max: {
                                                value: 1000,
                                                message: "Max value is $1000"
                                            },
                                        })}

                                    />
                                    {errors.price && <Form.Text
                                        className="text-muted validationText hasError">{errors.price.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="duration">
                                    <Form.Label>Duration*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Duration in Months"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('duration', {
                                            required: {
                                                value: "required",
                                                message: "Duration is required"
                                            },
                                            min: {
                                                value: 1,
                                                message: "Min value is 1"
                                            },
                                            max: {
                                                value: 12,
                                                message: "Max value is 12"
                                            },
                                        })}

                                    />
                                    {errors.duration && <Form.Text
                                        className="text-muted validationText hasError">{errors.duration.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>

                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder={"Enter description"}
                                        {...register("description", {
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300",
                                            },
                                            required: {
                                                value: false,

                                            },
                                        })}
                                    />
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className={"mb-3"} controlId="criteria">
                                    <Form.Label className='d-block'>Select Criteria*</Form.Label>
                                    <Form.Select className='formselect' aria-label="crietria"
                                                 {...register('criteria', {
                                        required: {
                                            value: "required",
                                            message: "Criteria is required"
                                        }
                                    })} onChange={(e) => {
                                                     setCriteria(e.target.value);
                                                 }}>

                                        {/*<option value={response?.criteria?.community}>{response?.criteria?.community}</option>*/}
                                        <option value={true}>community</option>
                                        {/*<option value={false}>other</option>*/}
                                    </Form.Select>
                                    {errors.criteria && <Form.Text
                                        className=" validationText hasError">{errors.criteria}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='mt-3' xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Add Package"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddPackage;
