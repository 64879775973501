import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AuthLayoutRoute from "./layouts/AuthLayout";
import Signin from "./views/auth/Signin";
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import Dashboard from "./views/dashboard/Dashboard";
import Users from "./views/users/Users";
import Pages from "./views/pages/Pages";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
import ChangePassword from "./views/auth/ChangePassword";
import {ToastContainer} from "react-toastify";
import ResetPassword from "./views/auth/ResetPassword";
import ForgotPassword from "./views/auth/ForgotPassword";
import VerifyCode from "./views/auth/VerifyCode";
import PushNotification from "./views/pushNotification/PushNotification";
import ListPushNotification from "./views/pushNotification/ListPushNotification";
import VerifyOTP from "./views/auth/VerifyOTP";
import Transactions from "./views/transactions/Transactions";
import Packages from "./views/packages/Packages";
import AddNewPage from "./views/pages/AddNewPage";
import About from "./views/PrivacyPolicy&T&C/About";
import EditPage from "./views/pages/EditPage";
import ViewProfile from "./views/auth/ViewProfile";
import EditProfile from "./views/auth/EditProfile";
import Constant from "./views/constants/Constant";
import ReportTypes from "./views/reportTypes/ReportTypes";
import AddNewReportType from "./views/reportTypes/AddNewReportType";
import EditReportType from "./views/reportTypes/EditReportType";
import GoLiveFormRequest from "./views/goLiveFormRequest/GoLiveFormRequest";
import ViewDetail from "./views/goLiveFormRequest/ViewDetail";
import Categories from "./views/categories/Categories";
import AddNewCategory from "./views/categories/AddNewCategory";
import EditCategory from "./views/categories/EditCategory";
import React from "react";
import EditPackage from "./views/packages/EditPackage";
import AddPackage from "./views/packages/AddPackage";
import ViewPackage from "./views/packages/ViewPackage"
import Badges from "./views/badges/Badges";
import AddBadge from "./views/badges/AddBadge";
import EditBadge from "./views/badges/EditBadge";
import ViewTransaction from "./views/transactions/ViewTransaction"
import Reports from "./views/reports/Reports";
import ViewReport from "./views/reports/ViewReport"
import ReportedPost from "./views/reportedPost/ReportedPost";
import ReportedPostViewDetail from "./views/reportedPost/ViewDetail";
import AddNewAdvertisement from "./views/advertisements/AddNewAdvertisement";
import Advertisement from "./views/advertisements/Advertisements";
import EditAdvertisement from "./views/advertisements/EditAdvertisement";
import AdAssignToCreators from "./views/advertisements/AdAssignToCreators";
import Post from "./views/posts/Post";
import DetailPost from "./views/posts/DetailPost";
import AdAssignToCategoryVideos from "./views/advertisements/AdAssignToCategoryVideos";

function App() {
  return (
    <Router>
      <Switch>


        {/**
         *
         *  Define all the page routes here
         *
        */}

          <Route exact isAuth={true} path="/pages/about" component={About}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/profile`} component={ViewProfile} />
          <DashboardLayoutRoute exact isAuth={false} path={`/edit-profile/:id`} component={EditProfile} />
          <DashboardLayoutRoute exact isAuth={true} path={`/change-password`} component={ChangePassword} />
          <DashboardLayoutRoute exact isAuth={true} path={`/dashboard`} component={Dashboard} />


          <DashboardLayoutRoute exact isAuth={true} path={`/manage-users`} component={Users}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/add-user`} component={AddNewUser} />
          <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/edit-user/:id`} component={EditUser} />

          <DashboardLayoutRoute exact isAuth={true} path={`/videos`} component={Post}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/videos/detail/:id`} component={DetailPost} />

          <DashboardLayoutRoute exact isAuth={true} path={`/advertisements`} component={Advertisement}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/advertisements/create-advertisement`} component={AddNewAdvertisement} />
          <DashboardLayoutRoute exact isAuth={true} path={`/advertisements/edit-advertisement/:id`} component={EditAdvertisement} />

          <DashboardLayoutRoute exact isAuth={true} path={`/ad-assign-to-creators`} component={AdAssignToCreators} />
          <DashboardLayoutRoute exact isAuth={true} path={`/ad-assign-to-category-videos`} component={AdAssignToCategoryVideos} />

          <DashboardLayoutRoute exact isAuth={true} path={`/pages`} component={Pages}/>
          <DashboardLayoutRoute exact  isAuth={true} path={`/pages/create-page`} component={AddNewPage} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/pages/edit-page/:id`} component={EditPage} />

          <DashboardLayoutRoute exact isAuth={true} path={`/categories`} component={Categories}/>
          <DashboardLayoutRoute exact  isAuth={true} path={`/categories/create-category`} component={AddNewCategory} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/categories/edit-category/:id`} component={EditCategory} />

          <DashboardLayoutRoute exact isAuth={true} path={`/report-types`} component={ReportTypes}/>
          <DashboardLayoutRoute exact  isAuth={true} path={`/report-types/create-report-type`} component={AddNewReportType} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/report-types/edit-report-type/:id`} component={EditReportType} />

          <DashboardLayoutRoute exact  isAuth={true} path={`/reported-posts`} component={ReportedPost} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/reported-posts/view-detail/:id`} component={ReportedPostViewDetail} />

          <DashboardLayoutRoute exact  isAuth={true} path={`/reports`} component={Reports} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/reports/view-detail/:id`} component={ViewReport} />
          <DashboardLayoutRoute exact isAuth={true} path={`/go-live-requests`} component={GoLiveFormRequest}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/go-live-requests/view-detail/:id`} component={ViewDetail}/>

          <DashboardLayoutRoute exact isAuth={true} path={`/transactions`} component={Transactions}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/transactions/view-detail/:id`} component={ViewTransaction}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/packages`} component={Packages}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/packages/add-package`} component={AddPackage}/>

          <DashboardLayoutRoute exact isAuth={true} path={`/packages/view-package/:id`} component={ViewPackage}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/packages/edit-package/:id`} component={EditPackage}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/badges`} component={Badges}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/badges/add-badge`} component={AddBadge}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/badges/edit-badge/:id`} component={EditBadge}/>
          <DashboardLayoutRoute exact isAuth={true} path={`/constants`} component={Constant}/>


          <DashboardLayoutRoute exact  isAuth={true} path={`/push-notification`} component={PushNotification} />
          <DashboardLayoutRoute exact  isAuth={true} path={`/push-notification-history`} component={ListPushNotification} />

        {/*</Route>*/}
        {/**
           *
           *  Authentication routes
           *
           */}
        <AuthLayoutRoute exact isAuth={true} path="/forgot-password" component={ForgotPassword} />
        <AuthLayoutRoute exact isAuth={true} path="/verify-code" component={VerifyCode} />
        <AuthLayoutRoute exact isAuth={true} path="/reset-password" component={ResetPassword} />
        <AuthLayoutRoute exact isAuth={true} path="/verify-otp" component={VerifyOTP} />
          <AuthLayoutRoute isAuth={true} path="/" component={Signin}/>



          {/**
           *
           *  Error routes
           *
           */}


    



      </Switch>
      <ToastContainer hideProgressBar autoClose={2000} />
    </Router>
  );
}

export default App;
