import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {GetCategoryById, UpdateCategory} from "../../services/categories.service";
import {GetAdvertisements} from "../../services/advertisements.service";
import Select from "react-select";


function EditCategory(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [advertisements, setAdvertisements] = useState([]);
    const [advertisementId, setAdvertisementId] = useState();
    const [selectedAd, setSelectedAd] = useState(null);
    const [videoAd, setVideoAd] = useState();

    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        getAdvertisements();
        await getCategoryByid(id);
    }, []);

    const getAdvertisements = async () => {
        await GetAdvertisements(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setAdvertisements(result.data);
            }
        });
    };

    const getCategoryByid = async (id) => {
        await GetCategoryById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setFormData(result.data);
                    setValue("name", result.data?.name);
                    // console.log("data.advertisement_id", result?.data?.advertisement !== null && result?.data?.advertisement !== undefined)
                    if (result?.data?.advertisement !== null && result?.data?.advertisement !== undefined) {
                        setSelectedAd({
                            label:result?.data?.advertisement?.name,
                            value:result?.data?.advertisement?._id
                        })

                        setVideoAd({
                            _id:result?.data?.advertisement?._id,
                            name:result?.data?.advertisement?.name
                        })
                    }

                    if(result?.data?.advertisement_id !== null && result?.data?.advertisement_id !== undefined){
                        setAdvertisementId(result.data.advertisement_id)
                    }
                }
                setData(result?.data);
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error) {
                return toast.error(error);
                // EmptyLocalStorage()
                // history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    }

    const selectedAdvertisement = async (item) => {
        let videoAd
        if(!item){
            setSelectedAd(null);
            setVideoAd({})
            setAdvertisementId("")
        }else{
            videoAd = advertisements.find((ad) => ad._id === item.value)
            setSelectedAd({
                label:videoAd.name,
                value:videoAd._id
            })
            setVideoAd({
                _id:videoAd._id,
                name:videoAd.name
            })
            setAdvertisementId(item.value)

            clearErrors("advertisement");
        }

        return videoAd;
    };


    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            name: data.name,
        }

        if(advertisementId !== ""){
            dt.advertisement_id = advertisementId
            dt.advertisement = videoAd
        }

        await UpdateCategory(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/categories');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error?.response?.status === 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    return (
        <div className="EditPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Category</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "Max length is 20"
                                            }
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className="d-block">
                                        Select advertisement
                                    </Form.Label>
                                    <Select
                                        options={advertisements && advertisements.map((e) => ({label: e.name,value: e._id,}))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        onChange={selectedAdvertisement}
                                        defaultValue={selectedAd}
                                        value={selectedAd}
                                    />
                                    {errors.advertisement && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.advertisement.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Update Category"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditCategory;