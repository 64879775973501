import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {GetPageById, UpdatePage} from "../../services/pages.service";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


function EditPage(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getPageByid(id);

    }, []);
    const getPageByid = async (id) => {
        await GetPageById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setFormData(result.data);
                    setValue("slug", result.data?.slug);
                    setValue("title", result.data?.title);
                    const contentState = ContentState.createFromBlockArray(htmlToDraft(result.data?.content).contentBlocks);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);

                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }
    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            slug: data.slug,
            title: data.title,
            status: true,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())) ,

        }
        await UpdatePage(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/pages');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })


    };

const onEditorStateChange=(newEditorState)=>{
    setEditorState(newEditorState)
}

    return (
        <div className="EditPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Page</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        readOnly
                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "title is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "Max length is 20"
                                            }
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            {/*<Col xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="slug">*/}
                            {/*        <Form.Label>Slug*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            readOnly*/}
                            {/*            {...register('slug', {*/}
                            {/*                required: {*/}
                            {/*                    value: "required",*/}
                            {/*                    message: "slug is required"*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 20,*/}
                            {/*                    message: "Max length is 20"*/}
                            {/*                }*/}
                            {/*            })} type="text"*/}
                            {/*        />*/}
                            {/*        {errors.slug && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.slug.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}

                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Page"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditPage;