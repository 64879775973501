import React, { useState, useEffect, useRef } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Bar, Chart, Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const ThemeChart = (props) => {
    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y:{
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        },
    };
    const [data, setData] = useState({
        labels:[],
        datasets:[],
        backgroundColor:""
    })

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];

    const data1 = {
        labels: labels,
        datasets: [{
            label: 'Active',
            data: [65, 59, 80, 81, 56, 55, 40,34,99,10,11,12],
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)'
            ],
            borderColor: [
                'rgb(75, 192, 192)',
            ],
            borderWidth: 1
        },
            {
                label: 'Inactive',
                data: [6, 5, 8, 8, 5, 5, 4,0,4,7,8,7],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgb(255, 99, 132)',
                ],
                borderWidth: 1
            }]
    };
    useEffect(()=>{
        if(props){
            setData({
                labels:props.datasets.labels,
                datasets:props.datasets.datasets,
            })
        }
    },[props])
    return (
        <div className='theme-chart'>
            <Bar options={options} data={data} />
        </div>
    )
}

export default ThemeChart