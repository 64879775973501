import React, {useState} from 'react'
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap'
import {FaRegUserCircle, FaUsers} from "react-icons/fa";
import {Logo} from "../utils/Svg"
import {EmptyLocalStorage, Logout} from "../services/auth/auth.service";
import {toast} from "react-toastify";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {RiDashboardFill, RiLogoutBoxFill, RiPagesFill} from "react-icons/ri";
import {BiBookContent} from "react-icons/bi";
import {MdPassword} from "react-icons/md";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {AiOutlineBell} from "react-icons/ai";
import swal from "sweetalert";
import {GoPackage} from "react-icons/go";
import {GrTransaction} from "react-icons/gr";

function Header(props) {
    let history = useHistory();
    const { slug } = useParams();
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(false);
    async function handleLogout() {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await Logout('web', 'f8kHlemeSkg1n29blKP4I7:APA91bHOHyxTtUZGKcnwY8ZmluOamF0oUneIQTCo2fcX0BKPNShSRfDnv49nLgLJQmMsIHtktTgkCFo3E3kVk1e8r7hP3Y73WigGPRtYqFREa2hxfyTohNpCjkzcFNt2FyflXEsSRSjW')
                toast.success("logged out successfully");
                await EmptyLocalStorage();
                history.push('/');
            }
        })
    }
    const checkNotifications = () => {
        const notificationCount = 10;
    /*
    *
    *  checking the notification count and set to true
    *
    */
        if(notificationCount > 1){
            setNotificationStatus(!notificationStatus);
        }

    }

    const navDropdownTitle = (<><FaRegUserCircle/></>);

    return (
        <div>
            <Navbar  className={"TruthGPT-header shadow d-none d-lg-block"}>
                <Container fluid>
                    <Navbar.Brand href={props.url}>
                        <div className="logo-class">
                            <Logo/>
                        </div>
                    </Navbar.Brand>

                    {/*{slug ==='terms-and-conditions' || slug ==='privacy-policy' ?*/}
                    {/*     '': <Nav className="ms-auto d-none d-lg-flex">*/}
                    {/*        <div className={"profile"}>*/}
                    {/*            <CgProfile/> Admin*/}
                    {/*        </div>*/}
                    {/*    </Nav> }*/}

                    <Nav className="ms-auto d-none d-lg-flex">
                        <NavDropdown align="end" title={navDropdownTitle} id="basic-nav-dropdown" className={"profileDropdown"}>
                            <NavDropdown.Item><NavLink to="/profile">Profile</NavLink></NavDropdown.Item>
                            <NavDropdown.Item><NavLink to="/change-password">Change Password</NavLink></NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout} href="javascript:;">Logout</NavDropdown.Item>
                        </NavDropdown>
                        {/*<div className={"profile"}>*/}
                        {/*    <CgProfile/> Admin*/}
                        {/*</div>*/}
                    </Nav>

                </Container>
            </Navbar>
            {/* Responsive Navbar Starts Here*/}
            <div className="responsive-navbar d-lg-none">
                {[false].map((expand) => (
                    <Navbar expand='false' bg="dark" className="navbar-dark imprint-header shadow">
                        <Container>
                            <Navbar.Brand href="/dashboard" >
                                <div className="logo-class">
                                    <Logo/>
                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle className='toggles' aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(true)}/>
                            <Navbar.Offcanvas
                                className='offcanvas-bg'
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                                show={showOffcanvas}
                                onHide={() => setShowOffcanvas(false)}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="res-head justify-content-end flex-grow-1 pe-3">
                                        <ul className="nav nav-pills flex-column mb-auto">
                                            <li className="nav-item">
                                                <NavLink to="/dashboard" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <RiDashboardFill/>
                                                    Dashbaord
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/manage-users" className="nav-link link-dark " activeClassName="active" onClick={() => setShowOffcanvas(false)} >
                                                    <FaUsers/>
                                                    Users
                                                </NavLink>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <NavLink to="/ads" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>*/}
                                            {/*        <FaBuysellads/>*/}
                                            {/*        Ads*/}
                                            {/*    </NavLink>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <NavLink to="/suggestions" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>*/}
                                            {/*        <BsBookFill/>*/}
                                            {/*        Suggestions*/}
                                            {/*    </NavLink>*/}
                                            {/*</li>*/}
                                            <li>
                                                <NavLink to="/contents" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <BiBookContent/>
                                                    Contents
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/packages" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <GoPackage/>
                                                    Packages
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/transactions" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <GrTransaction/>
                                                    Transactions
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/pages" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <RiPagesFill/>
                                                    Pages
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/push-notification-history" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <AiOutlineBell/>
                                                    Push Notifications
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/change-password" className="nav-link link-dark" activeClassName="active" onClick={() => setShowOffcanvas(false)}>
                                                    <MdPassword/>
                                                    Change Password
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="javascript:;" className="nav-link link-dark" activeClassName="active" onClick={handleLogout}>
                                                    <RiLogoutBoxFill/>
                                                    Logout
                                                </NavLink>
                                            </li>
                                        </ul>

                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </div>
            {/* Responsive Navbar Ends Here */}
        </div>
    )
}

export default Header
