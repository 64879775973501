import {apiUrl} from '../utils/constants';
import {GET} from './api.service.wrapper';

export const GetReports = async ( keyword = '', page = '', perPage = '', is_paginate = 1, module_id ='', report_module= '', status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (module_id && report_module) {
        params['module'] = report_module;
        params['module_id'] = module_id;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.reports, params);
    return response;
}

export const GetReportById = async (id) => {
    let response = '';
    // let params = {};
    // params['user_id'] = id;
    response = await GET(apiUrl.reports + `/${id}`);
    return response;
}