import {apiUrl} from '../utils/constants';
import {DELETE, GET, PATCH, POST} from './api.service.wrapper';


export const GetPosts = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null, type='graphic_post') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    if (type) {
        params['type'] = type;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.posts, params);
    return response;
}

export const GetPostById = async (id) => {
    let response = '';
    response = await GET(apiUrl.posts + '/' + id);
    return response;
}

export const AddPost = async (data) => {
    const response = await POST(apiUrl.posts, data);
    return response;
}

export const UpdatePost = async (id, data) => {
    return await PATCH(apiUrl.posts, id, data);
}

export const DeletePost = async (id) => {
    return await DELETE(apiUrl.posts, id);
}
