import S3 from "react-aws-s3";
import {apiUrl, constants} from "./constants";
import {SetS3BaseUrl} from "../services/api.service.wrapper";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const MoneyFormat = (value) => {
    let currency = Intl.NumberFormat(constants.LOCALE, {
        style: "currency",
        currency: constants.CURRENCY,
        useGrouping: false,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return currency.format(value)
}

export const randomIdWithName = (userName) => {
    let randomNumber = Math.random().nextInt(100000);
    return userName + randomNumber;
}


export const UploadImageToS3 = async (fileInput) => {
    const file = fileInput;
    const newFileName = file.name;
    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    const config = {
        bucketName: constants.S3CREDENTIAL.bucketName,
        dirName: constants.S3CREDENTIAL.dirName,
        region: constants.S3CREDENTIAL.region,
        accessKeyId: constants.S3CREDENTIAL.accessKeyId,
        secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
        s3Url: constants.S3CREDENTIAL.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    try {
        await ReactS3Client.uploadFile(file, newFileName).then((data) => {
            if (data && data.status === 204) {
                responseData = {
                    success: true,
                    message: constants.ERRORMESSAGES.fileSuccess,
                    data: data.location
                }
            } else {
                responseData = {
                    success: false,
                    message: constants.ERRORMESSAGES.fileError
                }
            }
        });
    } catch (err) {
        responseData = {
            success: false,
            message: constants.ERRORMESSAGES.fileError,
            error: err.message
        }
    }

    console.log(responseData,"res");
    return responseData;
}

export const createSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
}


export async function uploadToS3(fileType, fileName, fileContents, folder_name = 'other') {

    try{
        const body = {
            contentType: fileType
        };

        const config = {
            headers: {
                'x-access-token': constants.S3CREDENTIAL.app_token,
                'Access-Control-Allow-Origin':'*'
            }
        };

        //Here we are posting request with payload file type to get one time key access for uploading file
        const presignedPostUrlResponse = await fetch(SetS3BaseUrl() + apiUrl.get_s3_token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...config.headers
            },
            body: JSON.stringify(body)
        });

        //In case our request get failed

        if (!presignedPostUrlResponse.ok) {
            throw new Error('Failed to get presigned URL');
        }

        //Here from previous post response separating key and url and saving it in var for future use
        const presignedPostUrl = await presignedPostUrlResponse.json();
        let key;
        let url;
        if(presignedPostUrl?.data?.result?.fields?.Key){
            key=presignedPostUrl?.data?.result?.fields?.Key
        }
        if(presignedPostUrl?.data?.url){
            url=presignedPostUrl?.data?.url
        }

        //Here appending fields from previous response in next request payload
        const formData = new FormData();
        Object.entries(presignedPostUrl?.data?.result?.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        //Here appending file which we have to upload to S3
        formData.append('file', fileContents); // The file has to be the last element
        const response = await fetch(presignedPostUrl?.data?.result?.url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            body: formData
        });

        //After successfull upload it will gonna return empty response just with status 200 or 204
        //In case above condition return previously saved URL in var or return dynamic url which is comment below
        if (response.status == 200 || response.status == 204) {
            //For sending dynamic URL as a response uncomment below line of code and remove next return line
            //below URL include S3 endpoint and token and previously saved key
            // return constants.S3CREDENTIAL.s3EndPoint + apiUrl.get_s3_token +'/'+ key;
            return url
        }
        return false
    } catch (error) {
        console.error(error);
        return false;

    }
}