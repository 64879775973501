import {GET} from "./api.service.wrapper";
import {apiUrl} from "../utils/constants";

// export const GetDashboardData = async (search = '') => {
//     const response = await POST(apiUrl.dashboard, search);
//     return response;
// }
export const GetDashboardCounts = async () => {
    const response = await GET(apiUrl.dashboard,);
    return response;
}
export const GetSubscriptionChartData = async () => {
    const response = await GET(apiUrl.monthly_subscription);
    return response;
}
