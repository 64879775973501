import React, {useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Select from "react-select";
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {FadeLoader} from "react-spinners";
import {GetAdvertisements} from "../../services/advertisements.service";
import {toast} from "react-toastify";
import {GetCategories} from "../../services/categories.service";
import {AdAssignToCategory} from "../../services/adassigntocreator.service";

function AdAssignToCategoryVideos() {

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 1);
    let history = useHistory();
    const [categories, setCategories] = useState([]);
    // const [selectAllUsers, setSelectAllUsers] = useState(false);
    // const [selectSelectedUsers, setSelectSelectedUsers] = useState(false); // Change to true
    const [loader, setLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([])
    const [advertisements, setAdvertisements] = useState([]);
    const [videoAd, setVideoAd] = useState();

    const { register, reset, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        getCategories()
        getAdvertisements();
    }, [])

    const getCategories = async () => {
        await GetCategories(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setCategories(result.data);
            }
        })
    }

    const optionsCategories = (selectedCategory) => {
        let data = [];
        selectedCategory.map((item)=>{
            data.push(item.value)
        });
        setSelectedCategory(data);
        clearErrors('categories')
    }

    const getAdvertisements = async () => {
        await GetAdvertisements(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setAdvertisements(result.data);
            }
        });
    };

    const selectedAdvertisement = async (item) => {
        let videoAd = advertisements.find((ad) => ad._id === item.value)
        setVideoAd(videoAd)
        clearErrors("advertisement");

        return videoAd;
    };

    const onSubmit = async data => {

        if (!videoAd) {
            setError("advertisement", {
                message: "Select a advertisement.",
            });
            return false
        }else {
            clearErrors("advertisement");
        }
        if (selectedCategory.length === 0 ) {
            setError("categories", {
                type: "manual",
                message: "Select a category.",
            });
        } else {

            // Proceed with form submission
            let dt = {
                advertisements: videoAd,
                categories: selectedCategory,
            };
            setLoader(true)
            await AdAssignToCategory(dt).then(async (data) => {
                // console.log("data", data)
                setLoader(false)
                if (data.status) {
                    toast.success(data.message);
                    reset({
                        message: '',
                    })
                    window.location.reload()
                } else {
                    setLoader(false)
                    toast.error(data.message);
                }
            }).catch((error) => {
                setLoader(false)
                if (error.status == 401) {
                    history.push('/');
                }
                else {
                    console.log(error);
                    return toast.error(error.response.data.message);
                }
            })
        }

    };

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Ad Assign to Category Videos</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className="d-block">
                                        Select advertisement
                                    </Form.Label>
                                    <Select
                                        options={advertisements && advertisements.map((e) => ({label: e.name,value: e._id,}))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedAdvertisement}
                                    />
                                    {errors.advertisement && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.advertisement.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className='d-block'>Select Category</Form.Label>
                                    <Select
                                        isMulti
                                        options={ categories && categories.map(e => ({ label: e.name, value: e._id}))}
                                        className="basic-multi-select"
                                        onChange={optionsCategories}
                                        classNamePrefix="select"
                                    />
                                    {errors.categories && <Form.Text className="text-muted validationText hasError">{errors.categories.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Assign to category"} />
                                }
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AdAssignToCategoryVideos
