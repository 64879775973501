import {apiUrl} from '../utils/constants';
import {DELETE, GET, POST, PUT} from './api.service.wrapper';


export const GetPages = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.pages, params);
    return response;
}

export const GetPageById = async (id) => {
    let response = '';
    response = await GET(apiUrl.pages + '/' + id);
    return response;
}

export const AddPage = async (data) => {
    const response = await POST(apiUrl.pages, data);
    return response;
}

export const UpdatePage = async (id, data) => {
    const response = await PUT(apiUrl.pages, id, data);
    return response;
}

export const DeletePage = async (id) => {
    const response = await DELETE(apiUrl.pages, id);
    return response;
}
