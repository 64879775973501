import {apiUrl, BASE_URL, AUTH_PROJECT_TOKEN} from '../utils/constants';
import {DELETE, GET, POST, PUT} from './api.service.wrapper';
import axios from "axios";
import {GetToken} from "./auth/auth.service";
import {SetAuthBaseUrl} from "./users.service";



export const SetBaseUrl = () => {
    return BASE_URL;
}

const headers = {
    'x-access-token': AUTH_PROJECT_TOKEN,
    'Authorization': `Bearer ${GetToken()}`,
    'Access-Control-Allow-Origin': '*',
};

export const GetBadges = async ( keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    params['paginate'] = is_paginate;

    const response = await axios.get(SetBaseUrl() + apiUrl.badges, {
        params,
        headers
    });
    return response?.data
    // const response = await GET(apiUrl.user_list, params);
    // return response;
}

export const GetBadgeById = async (id) => {
    let response = '';
    // let params = {};
    // params['user_id'] = id;
    response = await GET(apiUrl.badges + `/${id}`);
    return response;
}

export const CreateBadge = async (data) => {
    const config = {
        headers: {
            'x-access-token': AUTH_PROJECT_TOKEN
        }
    }
    const response = axios.post(SetBaseUrl() + apiUrl.badges, data,config);
    return response;
}

export const UpdateBadge = async (id, data) => {
    console.log(id, data)
    const response = await axios.put(SetBaseUrl() + apiUrl.badges + "/" +id, data,{ headers });

    // const response = await PUT(apiUrl.user_update_id, id, data);
    return response;
}

export const DeleteBadge = async (id) => {
    const response = await DELETE(apiUrl.badges, id);
    return response;
}