import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';



export const GetPrivacyPolicyPage = async (slug) => {

    const response = await GET(apiUrl.privacy_policy+ slug);
    return response;
}

