import {apiUrl} from '../utils/constants';
import {DELETE, GET, PATCH, POST} from './api.service.wrapper';


export const GetCategories = async (keyword = '', page = '', perPage = '', is_paginate = true, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.categories, params);
    return response;
}

export const GetCategoryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.categories + '/' + id);
    return response;
}

export const AddCategory = async (data) => {
    const response = await POST(apiUrl.categories, data);
    return response;
}

export const UpdateCategory = async (id, data) => {
    const response = await PATCH(apiUrl.categories, id, data);
    return response;
}

export const DeleteCategory = async (id) => {
    const response = await DELETE(apiUrl.categories, id);
    return response;
}
