import imgNotFound from "../assets/images/broken.jpg"

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_BASE_URL}`
export const AUTH_PROJECT_TOKEN = `q4ABtmg2FsMs3WEeWh1r`
export const S3_BASE_URL = "https://s3.timbrtv.com/api/v1/"

const roles = {
    ROLE_ADMIN: 20,
    ROLE_USER: 10,
}

export const EXCLUDE_NUMBER_INPUT = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
]
export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]

const page = {
    PRIVACY_POLICY: 'privacy-policy',
    TERMS: 'terms-and-conditions'
}




const s3Credential = {

    bucketName: "tek-staging-timber-tv-backend",
    region: "us-east-1",
    accessKeyId: "AKIAUPLSLQUZGJ226V3H",
    secretAccessKey: "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",
    s3EndPoint: "https://s3.timbrtv.com/",
    fileSize: '10000000',
    app_token:'aDV3MqxXXrGUv867Zp3F'
}

const errorMessages = {
    fileSize: 'The file size is too large',
    fileSuccess: 'file uploaded successfully',
    fileError: 'Error in uploading file',
}

const contentType = {
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'application/pdf': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'audio/mpeg': 'audio',
    'video/mp4': 'video',
}

const chatMessage = {
    CONTENT: 0,
    CONTENT_SOFT_DELETE: 1,
    CONTENT_HARD_DELETE: 2,
}

const AdType = {
    IMAGE: "image",
    VIDEO: "video"
}

export const Media={
    IMAGE: 20,
    VIDEO: 10

}

export const MediaType = {
    [Media.IMAGE]: "Image",
    [Media.VIDEO]: "Video",
}
export const constants = {
    LOCAL_STORAGE_TOKEN: 'AUTH_ACCESS_TOKEN',
    LOCAL_STORAGE_USER: 'USER_DATA',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL,
    CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
    ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
    VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
    ROLES: roles,
    NOTIFICATIONTYPES: '',
    LOCALE: 'en-OM',
    CURRENCY: 'OMR',
    // PAGES: page,
    PERPAGE: 10,
    ORDER_BY_VALUE: 'desc',
    ORDER_BY_COLUMN: 'id',
    //IMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found.png',
    IMAGENOTFOUND: imgNotFound,
    //USERIMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found-user.png',
    USERIMAGENOTFOUND: 'imgNotFound',
    DEFAULTUSERIMAGE:'https://prod-backend-timbr.s3.us-east-1.amazonaws.com/mhIlAyVod4HA',
    COLLECTION: '',
    S3CREDENTIAL: s3Credential,
    ERRORMESSAGES: errorMessages,
    CONTENTTYPE: contentType,
    CHATMESSAGE: chatMessage,
    ADTYPE: AdType,
};

export const genders = {
    MALE: 10,
    FEMALE: 20,
}

export const weight_unit = {
    KG: 10,
    LBS: 20,
}

export const apiUrl = {
    login: 'auth/login',
    logout: 'auth/logout',
    forgot_password: 'auth/forgot-password',
    verify_otp: 'verify-otp',
    admin_verify_otp: 'admin_verify_otp',
    reset_password: 'reset-password',
    change_password: 'auth/change-password',
    pages: 'page',
    categories: 'categories',
    advertisements: 'advertisements',
    default_ad: 'advertisements/check/default',
    ad_assign_to_creator: 'ad-assign-to-creators',
    get_user_ads: 'ad-assign-to-creators/user/ad',
    get_user_ad_delete: 'ad-assign-to-creators/user/ad/delete',
    ad_assign_to_category_videos: 'advertisements/ad-assign-to-category',
    posts: 'posts',
    reported_posts: 'posts',
    reportTypes: 'report-type',
    go_live_form_request: 'go-live-requests',
    dashboard: 'dashboard',
    monthly_subscription: 'dashboard/month-subscription',
    dashboard_summary: 'dashboard-summary',
    users: 'users',
    user_create: 'auth/admin/create-user',
    user_list: 'auth/user-list',
    user_profile: 'user/get-profile-by-id',
    user_update_id: 'auth/update-user-by-id',
    user_delete: 'auth/admin/delete-user',
    register: 'register',
    ads: 'ads',
    get_s3_token: 'files',
    user_subscriptions : 'user-subscriptions' ,
    packages: 'packages',
    badges : 'badge',
    reports : 'admin/report',
    generate_otp:'generate_otp',
    backgrounds: 'backgrounds',
    mark_as_default: 'mark-as-default',
    interests: 'interests',
    suggestions:'suggestions',
    contents:'contents',
    privacy_policy:'page-by-slug/',
    terms_and_conditions:'page-by-slug/terms-and-conditions',
    push_notifications:'push-notifications',

}

export const userConstantData = {
    authType: "email",
    coverImage: "",
    role: 10,
    pushNotification: false,
    coordinates: [0,0],
    additionalFields:{
        is_online:false,
        social_account:"public",
        go_live_request:false,
        video_preference:[]
    }
}

export const customStyles = {
    rows: {
        style: {
            minHeight: '50px',
        },
    },
    headCells: {
        style: {
            color: '#000',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
            fontFamily: 'Poppins',
            padding: '5px',
            textTransform: 'uppercase',
            'align-items': 'left'
        },
    },
    cells: {
        style: {
            padding: '5px',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '19px',
            fontFamily: 'Poppins',
            'align-items': 'left'
        },
    },
}
export const GENDER_VALUES = {
    MALE: "Male",
    FEMALE: "Female",
    NONE: "Prefer-Not-To-Say",
}
export const GENDERS = {
    [GENDER_VALUES.NONE]: "Not Specified",
    [GENDER_VALUES.MALE]: "Male",
    [GENDER_VALUES.FEMALE]: "Female",
}

export const STATUS_VALUE = {
    ACTIVE: 'ACTIVE',
    DEACTIVE: 'DEACTIVE',
}
export const STATUS = {
    [STATUS_VALUE.ACTIVE]: "Active",
    [STATUS_VALUE.DEACTIVE]: "Deactive",
}

export const IDENTIFIERS_VALUE = {
    ANNUAL: 'anual_subscription',
    MONTHLY: 'monthly_subscription',
}
export const IDENTIFIERS = {
    [IDENTIFIERS_VALUE.ANNUAL]: "Annual Subscription",
    [IDENTIFIERS_VALUE.MONTHLY]: "Monthly Subscription",
}

export const DEFAULT_VALUE = {
    DEFAULT: 1,
    NOT_DEFAULT: 0,
}
export const DEFAULT = {
    [DEFAULT_VALUE.DEFAULT]: "Yes",
    [DEFAULT_VALUE.NOT_DEFAULT]: "No",
}
export const IS_VERIFIED_VALUE = {
    VERIFIED: 1,
    UN_VERIFIED: 0,
}

export const IS_VERIFIED = {
    [IS_VERIFIED_VALUE.VERIFIED]: "Verified",
    [IS_VERIFIED_VALUE.UN_VERIFIED]: "No",
}


// export const GO_LIVE_REQUEST = {
//     PENDING: 0,
//     REJECTED: 2,
//     ACCEPTED: 3,
// }
//
// export const GO_LIVE_REQUEST_VALUE = {
//     [GO_LIVE_REQUEST.ACCEPTED]: "Accepted",
//     [GO_LIVE_REQUEST.PENDING]: "Pending",
//     [GO_LIVE_REQUEST.REJECTED]: "Rejected",
// }

export const GO_LIVE_REQUEST = {
    ACCEPTED: true,
    REJECTED: false,
}

export const GO_LIVE_REQUEST_VALUE = {
    [GO_LIVE_REQUEST.ACCEPTED]: "Accepted",
    [GO_LIVE_REQUEST.REJECTED]: "Pending",
}

export const REPORT_TYPES = {
    POST:'post',
    COMMUNITY:'community',
    STREAMING:'streaming'
}
