import React, {useEffect, useState} from 'react'

import {Link} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'

function DashboardStat(props) {
    const RenderBody = () => {
        if(props.title === "Accounts"){
            return(
                <Row>
                    <Col xs={6}>
                        <h2>
                            {props.userCount}
                            <small>users</small>
                        </h2>
                    </Col>
                    <Col xs={6}>
                        <h2>
                            {props.proCount}
                            <small>professionals</small>
                        </h2>
                    </Col>
                </Row>
            )
        }
        else{
            return(
                <h2>{props.countMain}</h2>
            )
        }
    }
    return(
        <div className={`order-card card-outline`}>
            <div className="card-block w-100">
                <h6 className="m-b-20">
                    {props.title}
                </h6>
                {
                    RenderBody()
                }
            </div>
        </div>
    )

}

export default DashboardStat