import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {constants, customStyles} from "../../utils/constants";

import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";

import {Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";
import {DeletePackages, GetPackages} from "../../services/packages.service";
import DataTableExtensions from "react-data-table-component-extensions";
import {EmptyState} from "../../components/EmptyState";

function Packages(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    const getPackages = async () => {
        setLoading(true)

        await GetPackages(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];

                    setTotalRows(result.data.pagination.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            sno : counter++,
                            id:dt._id || '-',
                            name: dt.name || '-',
                            price: dt.price || '-',
                            duration: dt.duration || '-',
                            created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            }
            else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    useEffect(async () => {
        await getPackages();
    }, [keyword, page, perPage])

    const onPackagesDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this packages?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeletePackages(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPackages();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/packages/edit-package/' + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push('/packages/view-package/' + data.id, {
            data: data
        });
    }


    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View Detail</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onPackagesDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'sno',
            name: 'Id',
            center: true,
            selector: rowData => rowData.sno,
            cellExport : rowData => rowData.sno
        },
        {
            id: 'name',
            name: 'Title',
            center: true,
            maxWidth: '180px',
            selector: rowData => rowData.name,
            cellExport: rowData => rowData.name
        },
        {
            id: 'price',
            name: 'Price ($)',
            center: true,
            selector: rowData => rowData.price,
            cellExport: rowData => rowData.price
        },
        {
            id: 'duration',
            name: 'Duration',
            center: true,
            selector: rowData => rowData.duration,
            cellExport: rowData => rowData.duration
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export : false
        },
    ];

    return (
        <div className={"packagesPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Packages</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*{rows && rows.length > 0 ? (*/}
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                        {/*) : ('')}*/}
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/packages/add-package"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid" style={{"cursor":"pointer"}}>
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                <DataTableExtensions
                    columns={columns}
                    data={rows}
                    filter={false}
                    print={false}
                    exportHeaders
                    fileName='Packages'
                >
                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    persistTableHead
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover

                    paginationServer
                    paginationTotalRows={totalRows}

                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
                </DataTableExtensions>

                )}
            </div>
        </div>
    );
}

export default Packages;