import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GetGoLiveFormById} from "../../services/goLiveFormRequest.service";
import {constants} from "../../utils/constants";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Row} from "react-bootstrap";
import {GetPackageById} from "../../services/packages.service";

function ViewDetail(props) {
    const history = useHistory();
    const [data, setData] = useState({});

    useEffect(async () => {
        let id = props.match.params.id;
        await getPackageByid(id);

    }, []);

    const getPackageByid = async (id) => {
        await GetPackageById(id).then(async (result) => {
            console.log("result --- ", result)
            if (result.status) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setData(
                            {
                                _id: dt._id,
                                fullName: dt.name,
                                price : dt.price,
                                duration : dt.duration,
                                description : dt.description,
                                criteria : dt.criteria
                            }
                        )
                    }
                }
            } else {
                toast.error(result.data.data.message);
            }
        }).catch((error) => {
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast().error(error);
            }
        })
    }

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">Package Detail</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={8} md={7} lg={6} xl={7}>
                            <div className={"profileDetails"}>
                                <p><b>Name:</b> {data?.fullName}</p>
                                <p><b>Price:</b> ${data?.price}</p>
                                <p><b>Duration:</b> {data?.duration} months</p>
                                <p><b>Description:</b> {data?.description}</p>
                                {/*<p><b>Criteria:</b> {data?.criteria}</p>*/}
                            </div>
                        </Col>

                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewDetail