import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import {Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap"
import {MenuDots, SearchIcon} from "../../components/SvgIcons"
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {customStyles, GO_LIVE_REQUEST_VALUE} from "../../utils/constants";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import swal from "sweetalert";
import {DeleteGoLiveForm, GetGoLiveFormRequest, UpdateGoLiveForm} from "../../services/goLiveFormRequest.service";
import DataTableExtensions from "react-data-table-component-extensions";
import {EmptyState} from "../../components/EmptyState";

function GoLiveFormRequest() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [switchState, setSwitchState] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    const getGoLiveRequest = async () => {
        setLoading(true)
        await GetGoLiveFormRequest(keyword, page, perPage).then((result) => {
            if (result.status) {
                // console.log(result.data.data.length>0)
                if (result.data.data.length>0) {
                    const rowData = [];
                    let counter = 1; // Initialize the counter to start from 1
                    setTotalRows(result.data.pagination.total);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            sno: counter++,
                            _id: dt._id || '-',
                            user_name: dt?.fullName || '-',
                            status: dt?.status,
                            form_status: GO_LIVE_REQUEST_VALUE[dt?.status],
                            created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.data.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    }

    useEffect(async () => {
        await getGoLiveRequest();
    }, [keyword, page, perPage])

    const onView = (data) => {
        history.push('/go-live-requests/view-detail/' + data._id, {
            data: data
        });
    }

    const onDeleteRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteGoLiveForm(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getGoLiveRequest();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.message);
                    }
                })
            }
        });
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View Detail</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDeleteRecord(data._id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'sno',
            name: 'ID',
            sortable: true,
            center: true,
            selector: rowData => rowData.sno,
            cellExport: rowData => rowData.sno,
        },
        {
            id: 'user',
            name: 'User',
            center: true,
            sortable: true,
            selector: rowData => rowData.user_name,
            cellExport: rowData => rowData.user_name,
        },
        {
            id: 'status',
            name: 'Status',
            center: true,
            sortable: true,
            cell: (rowData) => <div className={rowData.form_status}>{rowData.form_status}</div>,
            selector: rowData => rowData.form_status,
            cellExport: rowData => rowData.form_status,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            grow: false,
            center: true,
            sortable: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export: false
        },
    ];

    return (
        <div className={"usersPage"}>
            <h1 className="page-heading">Go Live Request</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*{rows && rows.length > 0 ? (*/}
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                        {/*) : ('')}*/}
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        {/*<Link to={"/categories/create-category"}>*/}
                        {/*    <Button className={"btn btn-green-theme h40 w-100"}>*/}
                        {/*        <AddIconOutline /> Add New*/}
                        {/*    </Button>*/}

                        {/*</Link>*/}
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                    <DataTableExtensions
                        columns={columns}
                        data={rows}
                        filter={false}
                        print={false}
                        exportHeaders
                        fileName='GoLiveFormRequests'
                    >
                    <DataTable
                        columns={columns}
                        data={rows}
                        persistTableHead
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}

                        onChangePage={(page) => {
                            setPage(page);


                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                      height={'4'} width={'100%'} />}
                    />
                    </DataTableExtensions>
                )}
            </div>
        </div>
    )
}

export default GoLiveFormRequest
