import React, {useEffect, useState} from 'react'
import swal from "sweetalert"
import {Link, useHistory} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap"
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons"
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import {constants, customStyles} from "../../utils/constants";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {DeletePushNotification, GetPushNotification} from "../../services/pushnotification.service";
import moment from "moment";
import {EmptyState} from "../../components/EmptyState";
import BarLoader from "react-spinners/BarLoader";

function ListPushNotification() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getPushNotification();
    }, [keyword, page, perPage])

    const getPushNotification = async () => {
        setLoading(true)
        await GetPushNotification(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.data.length>0) {
                    const rowData = [];
                    
                    setTotalRows(result.data.pagination.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result?.data?.data.map((dt, index) => {
                        rowData.push({
                            sno:counter++,
                            id:dt._id || '-',
                            title: dt.title || '-',
                            message: dt.message || '-',
                            created_at: moment(dt.created_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }else{
                    setLoading(false)
                    setRows([]);
                }
            } else {
                setLoading(false)
                setRows([]);
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }

    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeletePushNotification(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPushNotification();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    // const onEdit = (data) => {
    //     history.push('/manage-users/edit-user/' + data.id, {
    //         data: data
    //     });
    // }



    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'Id',
            center: true,
            sortable: true,
            selector: rowData => rowData.sno,
            cellExport: rowData => rowData.sno,
        },
        {
            id: 'title',
            name: 'Title',
            center: true,
            selector: rowData => rowData.title,
            cellExport: rowData => rowData.title,
        },
        {
            id: 'message',
            name: 'Messages',
            center: true,
            selector: rowData => rowData.message,
            cellExport: rowData => rowData.message,
        },
        {
            id: 'created_at ',
            name: 'Created At ',
            center: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData),
            export: false

        },
    ];

    return (
        <div className={"usersPage"}>
            <h1 className="page-heading">Push Notification</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/*{rows && rows.length > 0 ? (*/}
                            <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                        {/*) : ('')}*/}
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/push-notification"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                {rows && rows.length == 0 ? (
                    <EmptyState/> // Render the EmptyState component when there are no records
                ): (
                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    paginationServer
                    paginationTotalRows={totalRows}

                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />

                )}
            </div>
        </div>
    )
}

export default ListPushNotification
