import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Col, Form, Row} from 'react-bootstrap'
import {constants, GO_LIVE_REQUEST_VALUE} from "../../utils/constants";
import {GetGoLiveFormById, UpdateGoLiveForm} from "../../services/goLiveFormRequest.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FadeLoader} from "react-spinners";

function ViewDetail(props) {
    const history = useHistory();
    const [data, setData] = useState({});
    const [selectedOption, setSelectedOption] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(async () => {
        let id = props.match.params.id;
        await getGoLiveRequestByid(id);
        setSelectedOption(data.status_value);
    }, [data.status_value]);

    const onUpdateStatus = async (id,status) => {
        setLoader(true)
        let dt = {
            status: status
        }
        await UpdateGoLiveForm(id, dt).then((result) => {
            if (result.status) {
                console.log(result.message)
                toast.success(result.message);
                setLoader(false)
                getGoLiveRequestByid(id)
                history.push('/go-live-requests');
            } else {
                toast.error(result.message);
            }
        })
    }

    const handleOptionChange = async (event) => {
        setSelectedOption(event.target.value);
        let status = event.target.value
        if(status !== ""){
            await onUpdateStatus(data._id, true)
        }else{
            setLoader(false)
            return toast.error("Select Status option");
        }
    };

    const getGoLiveRequestByid = async (id) => {
        await GetGoLiveFormById(id).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setData(
                            {
                                _id: dt._id,
                                fullName: dt.fullName,
                                dob: dt.dob,
                                fb: dt.fb,
                                youtube: dt.youtube || '-',
                                instagram: dt.instagram || '-',
                                twitter: dt.twitter || '-',
                                tiktok: dt.tiktok || '-',
                                status: GO_LIVE_REQUEST_VALUE[dt?.status],
                                status_value: dt?.status,
                                profileImage: dt?.user?.profileImage || constants.DEFAULTUSERIMAGE,
                            }
                        )
                    }
                }
            } else {
                toast.error(result.data.data.message);
            }
        }).catch((error) => {
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    }

    const formStyle = {
        display: 'inline'
    };

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">View Detail</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <div className={"profileImg mx-auto text-center mb-3 mb-sm-3 mb-md-0"}>
                                <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.DEFAULTUSERIMAGE;
                                }} src={data?.profileImage} alt={"Profile Image"} className={"img-fluid"} crossOrigin="anonymous"/>
                            </div>
                        </Col>
                        <Col xs={3} sm={4} md={4} lg={4} xl={4}>
                            <div className={"profileDetails"}>
                                <p><b>Full Name:</b> {data?.fullName}</p>
                                <p><b>dob:</b> {data?.dob}</p>
                                <p><b>facebook:</b> {data?.fb}</p>
                                <p><b>youtube:</b> {data?.youtube}</p>
                                <p><b>instagram:</b> {data?.instagram}</p>
                                <p><b>twitter:</b> {data?.twitter}</p>
                                <p><b>status:</b> <div className={data?.status} style={formStyle}>{data?.status}</div></p>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#f39633"} height={10}/>
                                </div>:''}
                            </div>
                        </Col>

                        <Col xs={3} sm={4} md={4} lg={4} xl={4}>
                            <Form.Label className='d-block'><b>Select Status</b></Form.Label>
                            <select value={selectedOption} onChange={handleOptionChange}>
                                <option value="">Select a status</option>
                                <option value="true">Accept</option>
                                {/*<option value="3" disabled={data.status === "Rejected"}>Accept</option>*/}
                                {/*<option value="2">Reject</option>*/}
                            </select>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewDetail
