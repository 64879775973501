import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';




export const GetTransactions = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.user_subscriptions, params);
    return response;
}

export const GetTransactionById = async (id) => {
    let response = '';
    // let params = {};
    // params['user_id'] = id;
    response = await GET(apiUrl.user_subscriptions + `/${id}`);
    return response;
}

export const DeleteTransaction = async (id) => {
    const response = await DELETE(apiUrl.user_subscriptions, id);
    return response;
}