import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {EmptyLocalStorage} from '../../services/auth/auth.service';

import {Col, Form, Row} from 'react-bootstrap';
import {FadeLoader} from 'react-spinners';
import {GetPackageById, UpdatePackage} from "../../services/packages.service";
import {CHARACTER_ARRAY_FOR_NUMBER_INPUT, EXCLUDE_NUMBER_INPUT} from "../../utils/constants";


function EditPackage(props){
    let history = useHistory();
    const {register, watch,setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "all",
    });
    const [loader, setLoader] = useState(false);
    const [height, setHeight] = useState('auto');
    const [editedValue, setEditedValue] = useState("");
    const[response, setResponse] = useState("")
    const[criteria,setCriteria] = useState(true)

    const getPackageById = async (id) => {
        await GetPackageById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setValue("name", result.data?.name);
                    setValue("price", result.data?.price);
                    setValue("duration", result.data?.duration);
                    setValue("description", result.data?.description);
                    // setValue("criteria", result.data?.criteria.community);

                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.status == false) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error("error");
            }
        })
    }

    useEffect(async () => {
        let id = props.match.params.id;
        await getPackageById(id);
    }, [props.match.params.id])

    const onSubmit = async data => {
        console.log(data,"update data")
        let id = props.match.params.id;
        setLoader(true)

        let dt = {
            duration: data.duration,
            price: data.price,
            name: data.name,
            description: data.description,
            criteria: {
                community : JSON.parse(criteria)
            }
        }
        console.log(dt,"dt")

        await UpdatePackage(id,dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success("success");
                history.push('/packages');
            } else {
                setLoader(false)
                toast.error("error");
            }
        }).catch((error) => {
            setLoader(false)
            if (error.status == false) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error("error");
            }
        })

    };

    const handleChange=(e)=>{
        if(e.target.value <= 0){
            setError('description',{ message: "Description is required"})
        }else if(e.target.value.length >= 300){
            setError('description',{ message: "Max length is 450"})
        }else{
            clearErrors('description')
        }
        setEditedValue(e.target.value)
        setHeight(`${e.target.scrollHeight}px`);
    }

    console.log(props,"props")
    return (
        <div className="EditBg">
            <h3 className="page-heading">Edit Package</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('name', {
                                            // required: {
                                            //     value: "required",
                                            //     message: "Name is required"
                                            // },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })}

                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Price*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Price in $"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('price', {
                                            // required: {
                                            //     value: "required",
                                            //     message: "Price is required"
                                            // },
                                            min: {
                                                value: 1,
                                                message: "Min value is $1"
                                            },
                                            max: {
                                                value: 1000,
                                                message: "Max value is $1000"
                                            },
                                        })}

                                    />
                                    {errors.price && <Form.Text
                                        className="text-muted validationText hasError">{errors.price.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="duration">
                                    <Form.Label>Duration*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Duration in Months"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            CHARACTER_ARRAY_FOR_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('duration', {
                                            // required: {
                                            //     value: "required",
                                            //     message: "Duration is required"
                                            // },
                                            min: {
                                                value: 1,
                                                message: "Min value is 1"
                                            },
                                            max: {
                                                value: 12,
                                                message: "Max value is 12"
                                            },
                                        })}

                                    />
                                    {errors.duration && <Form.Text
                                        className="text-muted validationText hasError">{errors.duration.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    {/*<Form.Control*/}
                                    {/*    type="text"*/}
                                    {/*    placeholder="Enter Description"*/}
                                    {/*    id={"formSignUpName"}*/}
                                    {/*    maxLength={300}*/}

                                    {/*    {...register('description', {*/}
                                    {/*        required: {*/}
                                    {/*            value: "required",*/}
                                    {/*            message: "Description is required"*/}
                                    {/*        },*/}
                                    {/*        // minLength: {*/}
                                    {/*        //     value: 3,*/}
                                    {/*        //     message: "Min length is 3"*/}
                                    {/*        // },*/}
                                    {/*        // maxLength: {*/}
                                    {/*        //     value: 25,*/}
                                    {/*        //     message: "Max length is 25"*/}
                                    {/*        // },*/}
                                    {/*    })}*/}

                                    {/*/>*/}

                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        {...register("description", {
                                            maxLength: {
                                                value: 300,
                                                message: "Max length is 300",
                                            },
                                            required: {
                                                value: false,
                                                // message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                                            },
                                        })}
                                    />
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className={"mb-3"} controlId="criteria">
                                    <Form.Label className='d-block'>Select Criteria*</Form.Label>
                                    <Form.Select className='formselect' aria-label="crietria" {...register('criteria', {
                                        required: {
                                            value: "required",
                                            message: "Criteria is required"
                                        }
                                    })}
                                                 onChange={(e) => {
                                                     setCriteria(e.target.value);
                                                 }}>
                                    >
                                        {/*<option value={response?.criteria?.community}>{response?.criteria?.community}</option>*/}
                                        <option value={true}>community</option>
                                        {/*<option value={false}>other</option>*/}
                                    </Form.Select>
                                    {errors.criteria && <Form.Text
                                        className=" validationText hasError">{errors.criteria}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='mt-3' xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Update Package"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditPackage;
