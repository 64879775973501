import axios from "axios";
import {constants} from '../utils/constants'

axios.defaults.baseURL = constants.BASE_URL;

axios.interceptors.response.use(
    response => response,
    error => {
        console.error(error)
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);

export const axiosService = axios;

export const api = axios.create({
    // baseURL: constants.BASE_URL,
    // headers: {
    //     Authorization: `Bearer ${localStorage.getItem("AUTH_ACCESS_TOKEN")}`,
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*'
    // }
    timeout: 60 * 1000,
});

export const API = axios.create({
    baseURL: constants.AUTH_BASE_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("AUTH_ACCESS_TOKEN")}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }

});

api.interceptors.request.use((config) => {
    let token = localStorage.getItem("AUTH_ACCESS_TOKEN")
    return {
        ...config,

        headers: {...
    {
        Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin'
    :
        '*'
    },...config.headers
}
    }
}, (exc) => Promise.reject(exc));
