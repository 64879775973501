import React, {useLayoutEffect, useState} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import {Col, Row} from 'react-bootstrap'
import {MdOutlineModeEditOutline} from 'react-icons/md'
import {GetProfile} from "../../services/users.service";
import {constants} from "../../utils/constants";
import {toast} from "react-toastify";

const initialData = {
    id: '',
    full_name: '',
    email: '',
    phone: '',
    image: constants.USERIMAGENOTFOUND,
}

function ViewProfile() {
    const history = useHistory();
    const [data, setData] = useState({});

    // useEffect(() => {
    //     getProfile()
    // }, [])
    //
    useLayoutEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
            if (result.data) {
                let dt = result.data;
                if (dt) {
                    await setData(
                        {
                            _id: dt._id,
                            fullName: dt.fullName,
                            email: dt.email,
                            profileImage: dt?.profileImage || constants.IMAGENOTFOUND,
                        }
                    )
                }
                }
            } else {
                toast.error(result.data.message);
            }
        }).catch((error) => {
            if (error) {
                // EmptyLocalStorage()
                history.push('/');
            } else {
                return toast().error(error.data.message);
            }
        })
    }

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">View Profile</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className={"profileImg mx-auto text-center mb-3 mb-sm-3 mb-md-0"}>
                                <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.USERIMAGENOTFOUND;
                                }} src={data?.profileImage} alt={"Profile Image"} className={"img-fluid"} crossOrigin="anonymous"/>
                            </div>
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={6} xl={7}>
                            <div className={"profileDetails"}>
                                <h4>{data?.fullName}</h4>
                                <p><b>Email:</b> {data?.email}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} md={2} lg={3} xl={2} className={'text-sm-end text-start'}>
                            <NavLink className={"floating-link"} to={{pathname: "/edit-profile/"+ data._id, state: data}}>
                                <MdOutlineModeEditOutline/> Edit Profile
                            </NavLink>
                        </Col>

                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewProfile
