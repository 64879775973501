import React, {useState} from 'react'
import {useHistory} from "react-router";
import {Button, Form, FormControl, InputGroup} from 'react-bootstrap'
import {Logo} from "../../utils/Svg"
import {useForm} from "react-hook-form"
import {EmptyLocalStorage, Login} from "../../services/auth/auth.service"
import {constants} from "../../utils/constants";
import {toast} from "react-toastify";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {FadeLoader} from "react-spinners"

function Signin() {
    const history = useHistory();
    const [pass, TogglePass] = useState(true);
    const [loader, setLoader] = useState(false);
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setLoader(true)
        const email = data.email;
        const password = data.password;
        await Login(email, password, 'email', constants.ROLES.ROLE_ADMIN, 'web', 'f8kHlemeSkg1n29blKP4I7:APA91bHOHyxTtUZGKcnwY8ZmluOamF0oUneIQTCo2fcX0BKPNShSRfDnv49nLgLJQmMsIHtktTgkCFo3E3kVk1e8r7hP3Y73WigGPRtYqFREa2hxfyTohNpCjkzcFNt2FyflXEsSRSjW').then((res) => {
            if (res.data.status) {
                if(res.data.data?.user?.role?.roleId == constants.ROLES.ROLE_ADMIN){
                    setLoader(false)
                    toast.success(res.data.message);
                    history.push('/dashboard')
                }else {
                    setLoader(false)
                    toast.error("incorrect user");
                    EmptyLocalStorage()
                }
            } else {
                setLoader(false)
                toast.error(res.message);
            }
        }).catch((error) => {
            setLoader(false);
            toast.error("Invalid credentials");
        })
    };

    return (
        <div className={"text-center authLayout"}>
            <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                    <div className="logo-signin">
                        <Logo/>
                    </div>
                </a>
                <h1 className="h5 mb-3 font-weight-normal">Sign in to your account</h1>
                <Form.Group className="mb-3">

                    <Form.Control
                        placeholder="Email address"
                        id={"formSignInEmail"}
                        {...register('email', {
                            required: {
                                value: "required",
                                message: "Email is required"
                            },
                            maxLength: {
                                value: 255,
                                message: "Max length is 255"
                            },
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Entered value does not match email format"
                            }
                        })}
                        type="email"
                    />
                    {errors.email &&
                    <span className={"validation-error pr-5"} role="alert">{errors.email.message}</span>}

                    {/*password*/}
                    <div className={"pass-field"}>
                        <InputGroup controlId="passowrd">
                            <FormControl
                                placeholder="Enter Password"
                                autoComplete={"off"}
                                {...register('password', {
                                    minLength: {
                                        value: 6,
                                        message: "Min length is 6"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    }
                                })}
                                type={pass ? "password" : "text"}/>
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                {
                                    pass ? <FaEye/> : <FaEyeSlash/>
                                }
                            </Button>
                        </InputGroup>
                        {errors.password &&
                        <span className={"validation-error pr-5"} role="alert">{errors.password.message}</span>}
                    </div>
                    {/*password*/}

                </Form.Group>


                {/*<ul className={"list-unstyled mt-3 d-block"}>*/}
                {/*    <li className={"d-block text-end"}>*/}
                {/*        <NavLink to={"/forgot-password"}>*/}
                {/*            Forgot Password*/}
                {/*        </NavLink>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                <div className="mt-4 d-grid gap-2 loader_custom">
                    {loader ?<div className="spin-loader"> <FadeLoader color={"#FFF"} height={10}/></div>
                        :
                        <Button variant="primary" size="lg" type="submit" className={"btn-green-theme text-uppercase"}>
                            Sign In
                        </Button>
                    }
                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by Tekrevol {new Date().getFullYear()}</p>
            </Form>
        </div>
    )
}

export default Signin
