import React from 'react'
import {NavLink, useHistory} from "react-router-dom";
import {EmptyLocalStorage, Logout} from "../services/auth/auth.service";
import {toast} from "react-toastify";
import {
    RiAdvertisementLine,
    RiListUnordered,
    RiLiveLine,
    RiPagesLine,
    RiStickyNoteLine,
    RiTodoLine
} from "react-icons/ri";

import {GrTransaction} from "react-icons/gr";
import {GoBookmark, GoPackage} from "react-icons/go";
import {MdOutlineAssignmentTurnedIn, MdOutlineDashboard} from "react-icons/md";
import {HiOutlineUsers} from "react-icons/hi";
import {AiOutlineBell} from "react-icons/ai";
import {BiSolidVideos} from "react-icons/bi";
import {FaBan} from "react-icons/fa";
import swal from "sweetalert";


function SideBar() {
    let history = useHistory();
    async function handleLogout() {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await Logout('web', 'f8kHlemeSkg1n29blKP4I7:APA91bHOHyxTtUZGKcnwY8ZmluOamF0oUneIQTCo2fcX0BKPNShSRfDnv49nLgLJQmMsIHtktTgkCFo3E3kVk1e8r7hP3Y73WigGPRtYqFREa2hxfyTohNpCjkzcFNt2FyflXEsSRSjW')
                toast.success("logged out successfully");
                await EmptyLocalStorage();
                history.push('/');
            }
        })
    }
    return (

        <div className="d-flex flex-column flex-shrink-0 p-3 bg-light">
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <NavLink to="/dashboard" className="nav-link link-dark" activeClassName="active" >
                            <MdOutlineDashboard/>
                            Dashboard
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/manage-users" className="nav-link link-dark " activeClassName="active" >
                            <HiOutlineUsers/>
                            Users
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/go-live-requests" className="nav-link link-dark" activeClassName="active">
                            <RiLiveLine/>
                            Go Live Request
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/videos" className="nav-link link-dark" activeClassName="active">
                            <BiSolidVideos/>
                            Videos
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/ad-assign-to-category-videos" className="nav-link link-dark" activeClassName="active">
                            <MdOutlineAssignmentTurnedIn/>
                            Ad Assign to Category Videos
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/ad-assign-to-creators" className="nav-link link-dark" activeClassName="active">
                            <MdOutlineAssignmentTurnedIn/>
                            Ad Assign to Creators
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/advertisements" className="nav-link link-dark" activeClassName="active">
                            <RiAdvertisementLine/>
                            Advertisements
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/packages" className="nav-link link-dark" activeClassName="active">
                            <GoPackage/>
                            Packages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/badges" className="nav-link link-dark" activeClassName="active">
                            <GoBookmark/>
                            Badges
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/transactions" className="nav-link link-dark" activeClassName="active">
                            <GrTransaction/>
                            Transactions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/pages" className="nav-link link-dark" activeClassName="active">
                            <RiPagesLine/>
                            Pages
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/categories" className="nav-link link-dark" activeClassName="active">
                            <RiListUnordered/>
                            Categories
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/reported-posts" className="nav-link link-dark" activeClassName="active">
                            <FaBan/>
                            Reported Posts
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/reports" className="nav-link link-dark" activeClassName="active">
                            <RiStickyNoteLine/>
                            Reports
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/report-types" className="nav-link link-dark" activeClassName="active">
                            <RiTodoLine/>
                            Report Types
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/push-notification-history" className="nav-link link-dark" activeClassName="active">
                            <AiOutlineBell/>
                            Push Notifications
                        </NavLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavLink to="/change-password" className="nav-link link-dark" activeClassName="active">*/}
                    {/*        <MdPassword/>*/}
                    {/*        Change Password*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <NavLink to="javascript:;" className="nav-link link-dark" activeClassName="active" onClick={handleLogout}>*/}
                    {/*        <RiLogoutBoxLine/>*/}
                    {/*        Logout*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                </ul>

        </div>
    )
}

export default SideBar
