import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {GetReportTypeById, UpdateReportType} from "../../services/reportTypes.service";


function EditReportType(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getReportTypeByid(id);

    }, []);
    const getReportTypeByid = async (id) => {
        await GetReportTypeById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData(result.data);
                    setFormData(result.data);
                    setValue("name", result.data?.name);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    }
    const onSubmit = async data => {
        setLoader(true)
        let id = props.match.params.id;
        let dt = {
            name: data.name,
            status: true,
        }
        await UpdateReportType(id, dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/report-types');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })


    };

    return (
        <div className="EditPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Report Type</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "Max length is 20"
                                            }
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Update Report Type"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditReportType;