import {apiUrl, AUTH_BASE_URL, AUTH_PROJECT_TOKEN, BASE_URL} from '../utils/constants';
import {GET, GET_AUTH, POST, PUT} from './api.service.wrapper';
import {GetAuthUser, GetToken, GetUserRole} from "../services/auth/auth.service"
import axios from "axios";

const user = GetAuthUser();
let role = GetUserRole();

export const SetAuthBaseUrl = () => {
    return AUTH_BASE_URL;
}
export const SetBaseUrl = () => {
    return BASE_URL;
}
const token = await GetToken();
const headers = {
    'x-access-token': AUTH_PROJECT_TOKEN,
    'Authorization': `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
};

export const GetProfile = async () => {
    // console.log("user ===", user)
    let response = '';
    let params = {};
    params['user_id'] = user._id;
    response = await GET(apiUrl.user_profile, params);
    return response;
}

export const UpdateProfile = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await PUT(apiUrl.update_profile, id, data, config);
}

export const GetUsers = async (keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    params['role'] = 10;
    params['order_by'] = 'createdAt';
    params['order'] = -1;

    params['paginate'] = is_paginate;

    return await GET_AUTH(apiUrl.user_list, params)
}

export const GetUserById = async (id) => {
    let response = '';
    let params = {};
    params['user_id'] = id;
    response = await GET(apiUrl.user_profile, params);
    return response;
}

export const CreateUser = async (data) => {
    const config = {
        headers: {
            'x-access-token': AUTH_PROJECT_TOKEN
        }
    }
    return axios.post(SetAuthBaseUrl() + apiUrl.user_create, data, config);
}

export const UpdateUser = async (id, data) => {
    return await axios.patch(SetAuthBaseUrl() + apiUrl.user_update_id + "/" + id, data, {headers});
}

export const DeleteUser = async (id) => {
    let token = GetToken()
    headers['Authorization'] = `Bearer ${token}`

    let data = {
        accountDeleteReason:"deleted by Admin"
    }
    return await axios.patch(SetAuthBaseUrl() + apiUrl.user_delete + "/" + id, data, {headers});
}

export const ChatNotification = async (data) => {
    return await POST(apiUrl.chat_notification, data);
}

export const getPresignedPostUrl = async (fileType, fileName) => {
    let response = '';
    response = await GET(apiUrl.get_s3_token + '?image_type=' + fileType + "&file_name=" + fileName);
    return response.data;
}