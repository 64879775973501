import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from 'draft-js';
import {uploadToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {AddAdvertisement, CheckDefaultAd} from "../../services/advertisements.service";

function AddNewAdvertisement() {
    let history = useHistory();
    const [file, setFile] = useState(null)
    const [thumbnailFile, setThumbnailFile] = useState(null)
    const [mediaFile, setMediaFile] = useState(null)
    const [isThumbnailUpload, setThumbnailIsUpload] = useState(false)
    const [isMedialUpload, setMediaIsUpload] = useState(false)
    const [duration, setDuration] = useState(0)
    const [videoDuration, setVideoDuration] = useState(0)
    const [thumbnail, setThumbnail] = useState(null)
    const [skip, setSkip] = useState()
    const [mediaUrl, setMediaUrl] = useState(null)
    const [isDefault, setIsDefault] = useState()
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [fileInputDisabled, setFileInputDisabled] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {

        let uploadThumbnail
        let uploadVideo
        // console.log("mediaUrl", mediaUrl)
        if(mediaUrl !== null){
            clearErrors('media')

            const fileType = mediaFile.type;
            const validVideoTypes = ['video/mp4']; // Add more video types as needed

            if (!validVideoTypes.includes(fileType)) {
                setLoader(false)
                setFileInputDisabled(false)
                setError('media', { message: 'Invalid file type. Only mp4 video file are accepted.' });
                return;
            }else {
                setFileInputDisabled(true)
                setLoader(true)
                clearErrors('media')
                uploadVideo = await uploadToS3(mediaFile.type,mediaFile.name,mediaFile,'advertisements');
            }
        }else{
            setError('media',{message:"Media is required"})
        }
        if(thumbnail !== null){
            clearErrors('thumbnail')

            const thumbnailType = thumbnailFile.type;
            const validThumbnailTypes = ['image/jpeg', 'image/jpg', 'image/png'];

            if (!validThumbnailTypes.includes(thumbnailType)) {
                setLoader(false)
                setFileInputDisabled(false)

                setError('thumbnail', { message: 'Invalid file type. Only jpeg, jpg, png files are accepted.' });
                return;
            }else{
                setLoader(true)
                setFileInputDisabled(true)

                clearErrors('thumbnail')
                uploadThumbnail = await uploadToS3(thumbnailFile.type,thumbnailFile.name,thumbnailFile,'advertisements');
            }

        }else{
            setError('thumbnail',{message:"Thumbnail is required"})
        }

        const maxDuration = 5 * 60; // 5 minutes in seconds
        const minDuration = 1 * 60; // 1 minute in seconds

        if (videoDuration > maxDuration) {
            setError('media', { message: 'Video duration exceeds 5 minutes.' });
            return;

        }
        // else if (videoDuration < minDuration) {
        //     setError('media', { message: 'Video duration is less than 1 minute.' });
        //     return;
        // }

        setLoader(true)

        if (uploadVideo) {
            if (!uploadVideo) {
                setLoader(false)
                toast.error(uploadVideo.message);
                return false;
            }
        } else {
            setLoader(false)
            setFileInputDisabled(false)
            toast.error('Can\'t Upload Media');
            return false;
        }

        if (uploadThumbnail) {
            if (!uploadThumbnail) {
                setLoader(false)
                toast.error(uploadThumbnail.message);
                return false;
            }
        } else {
            setLoader(false)
            setFileInputDisabled(false)
            toast.error('Can\'t Upload Thumbnail');
            return false;
        }
        // console.log("skip ==", skip)
        let dt = {
            name: data.name,
            thumbnail: uploadThumbnail,
            duration: duration,
            skip: (skip == '1')?true:false,
            media_url: uploadVideo,
            url: data.url,
            is_default: (isDefault == '1')?1:0,
        }
        // console.log("dt ==", dt)
        await AddAdvertisement(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/advertisements');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error) {
                // EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })
    };

    const uploadMedia = async (fileInput) => {
        const file = fileInput[0];
        // console.log("fileInput[0]", fileInput[0])
        const fileUrl = URL.createObjectURL(fileInput[0]);
        setMediaUrl(fileUrl);
        setMediaFile(file);

        if (!file) {
            setError('media', { message: 'Media is required' });
            return;
        }

        const fileType = file.type;
        const validVideoTypes = ['video/mp4']; // Add more video types as needed

        if (!validVideoTypes.includes(fileType)) {
            setError('media', { message: 'Invalid file type. Only mp4 video file are accepted.' });
            return;
        }else {
            clearErrors('media');
        }

        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
            URL.revokeObjectURL(video.src);
            const maxDuration = 5 * 60; // 5 minutes in seconds
            const minDuration = 1 * 60; // 1 minute in seconds
            const duration = Math.round(video.duration);

            setVideoDuration(duration)
            if (duration > maxDuration) {
                setError('media', { message: 'Video duration exceeds 5 minutes.' });
            }
            // else if (duration < minDuration) {
            //     setError('media', { message: 'Video duration is less than 1 minute.' });
            // }
            else {
                clearErrors('media');
                setDuration(duration);
                setMediaIsUpload(true);
            }
        };

        video.src = fileUrl;
    };

    const uploadThumbnail = async (fileInput) => {
        const thumbnailFile = fileInput[0];
        let fileUrl = URL.createObjectURL(fileInput[0])
        setThumbnail(fileUrl)
        setThumbnailFile(fileInput[0])
        setThumbnailIsUpload(true)

        if (!thumbnailFile) {
            setError('thumbnail', { message: 'Thumbnail is required' });
            return;
        }

        const fileType = thumbnailFile.type;
        const validThumbnailTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!validThumbnailTypes.includes(fileType)) {
            setError('thumbnail', { message: 'Invalid file type. Only jpeg, jpg, png files are accepted.' });
            return;
        }else {
            clearErrors('thumbnail');
        }
    }

    const handleOnChangeDefault = async (selectedValue) => {
        if(selectedValue == ""){
            setError("is_default", {
                type: "manual",
                message: "Select a default option.",
            });
        }

        clearErrors('is_default');
        if(selectedValue){
            await CheckDefaultAd(selectedValue).then(async (data) => {
                setLoader(false)
                if (data.status) {
                    setIsDefault(selectedValue)
                    toast.success(data.message);
                }else{
                    setIsDefault("");
                    setLoader(false)
                    toast.error(data.message);
                }
            }).catch((error) => {
                setLoader(false)
                if (error) {
                    history.push('/');
                } else {
                    return toast.error(error.data.message);
                }
            })
        }


    }

    return (
        <div className="AddPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add Advertisement</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={7}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        {...register('name',{
                                            required: 'Name is required',
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: 'Title cannot exceed 20 characters',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Enter Name"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Media* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file duration: 5 min)</Form.Text> </Form.Label>
                                    <input
                                        {...register('media', {
                                            required: 'Media is required',
                                        })}
                                        onChange={(e) => uploadMedia(e.target.files)}
                                        type='file'
                                        accept={'video/mp4,video/x-m4v,video/*'}
                                        class="custom-file-input"
                                        id='media'
                                        disabled={fileInputDisabled}
                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*{isMedialUpload ?*/}
                        {/*    <Row>*/}
                        {/*        <Col xs={12} sm={12} md={6} lg={6} xl={6}>*/}
                        {/*           */}
                        {/*            <div>*/}
                        {/*                /!*<video src={mediaUrl} controls width="300" />*!/*/}

                        {/*                <video controls width="300">*/}
                        {/*                    <source src={mediaUrl} type="video/mp4" />*/}
                        {/*                    Your browser does not support the video tag.*/}
                        {/*                </video>*/}
                        {/*            </div>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*    :  ""*/}
                        {/*}*/}

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Thumbail* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 3 mb)</Form.Text> </Form.Label>
                                    <input
                                        {...register('thumbnail', {
                                            required: 'Thumbnail is required',
                                        })}
                                        onChange={(e) => uploadThumbnail(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='thumbnail'
                                        disabled={fileInputDisabled}
                                    />

                                    <br/>
                                    {errors.thumbnail && <Form.Text
                                        className=" validationText hasError">{errors.thumbnail.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {/*{isThumbnailUpload ?*/}
                        {/*    <Row>*/}
                        {/*        <Col xs={12} sm={12} md={6} lg={6} xl={6}>*/}
                        {/*            <ul className={"list-inline list-variations"}>*/}
                        {/*                <li className={"list-inline-item d-flex justify-content-between mb-0"}>*/}
                        {/*                    <img  src={thumbnail} width="50%" height="50%"/>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*    :  ""*/}
                        {/*}*/}

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Url*</Form.Label>
                                    <Form.Control
                                        {...register('url',{
                                            required: 'Url is required',
                                            maxLength: {
                                                value: 100,
                                                message: 'Ur cannot exceed 100 characters',
                                            },
                                        })}
                                        type="url"
                                        placeholder="Enter Url"
                                    />
                                    {errors.url && <Form.Text
                                        className=" validationText hasError">{errors.url.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Form.Group className={"mb-3"} >
                                    <Form.Label className='d-block'>Select skip option*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type"
                                                 {...register('skip', {
                                                     required: 'skip is required',
                                                 })} onChange={(e) => {setSkip(e.target.value);
                                        if (skip){
                                            clearErrors('skip')
                                        }
                                    }}>
                                        <option value={""}>Select</option>
                                        <option value={"1"}>Yes</option>
                                        <option value={"0"}>No</option>
                                    </Form.Select>
                                    {errors.skip && <Form.Text
                                        className=" validationText hasError">{errors.skip.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Form.Group className={"mb-3"} >
                                    <Form.Label className='d-block'>Select default</Form.Label>
                                    <Form.Select className='formselect' aria-label="type" value={isDefault}
                                                 {...register('is_default', {
                                                 })} onChange={(e) => {handleOnChangeDefault(e.target.value);
                                        if (isDefault){
                                            clearErrors('is_default')
                                        }
                                    }}>
                                        <option value={""}>Select</option>
                                        <option value={"1"}>Yes</option>
                                        <option value={"0"}>No</option>
                                    </Form.Select>
                                    {errors.is_default && <Form.Text
                                        className=" validationText hasError">{errors.is_default.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Save"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewAdvertisement;