import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from 'draft-js';
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {AddReportType} from "../../services/reportTypes.service";


function AddNewReportType() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {
        setLoader(true)
        let dt = {
            name: data.name,
            status: true,
        }
        await AddReportType(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/report-types');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.data.message);
            }
        })


    };

    return (
        <div className="AddPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add Report Type</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        {...register('name',{
                                            required: 'Name is required',
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: 'Title cannot exceed 20 characters',
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className=" validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                        </Row>

                        <Row>
                            <Col  xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Save Report Type"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewReportType;