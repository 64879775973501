import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';


// export const GetAdsList = async () => {
//     let response = await GET(apiUrl.sports_list);
//     return response;
// }

export const UpdateAds = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.ads, id, data, config);
    return response;
}


export const GetAds = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.ads, params);
    return response;
}

export const GetAdById = async (id) => {
    let response = '';
    response = await GET(apiUrl.ads + '/' + id);
    return response;
}

export const CreateAd = async (data) => {
    const response = await POST(apiUrl.ads, data);
    return response;
}

export const UpdateAd = async (id, data) => {
    const response = await PUT(apiUrl.ads, id, data);
    return response;
}

export const DeleteAd = async (id) => {
    const response = await DELETE(apiUrl.ads, id);
    return response;
}

export const GenerateOtp = async (data) => {
    const response = await POST(apiUrl.generate_otp, data);
    return response;
}