import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Label, Button} from 'react-bootstrap'
import {Logo} from "../../utils/Svg"
import {useForm} from "react-hook-form";
import { useHistory } from "react-router";
import {EmptyLocalStorage, VerifyOtpApi} from "../../services/auth/auth.service"
import {toast} from "react-toastify";
import {FadeLoader} from "react-spinners";
import OTPInput, { ResendOTP } from "otp-input-react";

function VerifyCode({location}) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [disabled, setdisabled] = useState(true);
    const [OTP, setOTP] = useState("");
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useLayoutEffect(() => {
        if(!location.state?.email){
            history.push('/');
        }
    }, [])

    useLayoutEffect(() => {
        if (OTP.length==4){
            setdisabled(false)
            console.log(OTP)
        }else{
            setdisabled(true)
        }

    }, [OTP])

    const onSubmit = async data => {
        setLoader(true)
        let verificationCode = OTP
        let email = location.state.email
        await VerifyOtpApi(verificationCode, email).then((data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/reset-password', {
                    verificationCode: verificationCode,
                    email: email
                })
                setLoader(false)
            }
            else{
                toast.error(data.message);
                setLoader(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                setLoader(false)
                return toast.error(error.response.data.message);
            }
        })

    };

    return (
        <div className={"text-center authLayout"}>
            <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                  <Logo/>  </a>
                <h1 className="h5 mb-3 font-weight-normal">Code Verification</h1>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <OTPInput  className={"otpInput"} value={OTP} onChange={setOTP}  OTPLength={4} otpType="number"  disabled={false}  />
                    {/*<ResendOTP className={"resendOTP"} onResendClick={() => console.log("Resend clicked")} />*/}
                       </Form.Group>


                <div className="d-grid gap-2">
                    {loader ?<div className="spin-loader"> <FadeLoader color={"#FFF"} height={10}/></div>
                        :
                        <Button variant="primary" size="lg" type="submit" className={"btn-green-theme"} disabled={disabled} >
                            Verify Code
                        </Button>
                    }

                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by Tekrevol {new Date().getFullYear()}</p>
            </Form>
        </div>
    )
}

export default VerifyCode
