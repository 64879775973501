import {DELETE, GET, POST} from "./api.service.wrapper";
import {apiUrl} from "../utils/constants";

export const GetPushNotification = async (keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    params['paginate'] = is_paginate;

    const response = await GET(apiUrl.push_notifications, params);
    return response;
}

export const CreatePushNotification = async (data) => {
    const response = await POST(apiUrl.push_notifications, data);
    return response;
}
export const DeletePushNotification = async (id) => {
    const response = await DELETE(apiUrl.push_notifications, id);
    return response;
}