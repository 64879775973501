import {api, axiosService} from './api.service'
import {AUTH_PROJECT_TOKEN, BASE_URL, constants, S3_BASE_URL} from "../utils/constants";
import {SetAuthBaseUrl} from "./auth/auth.service";
import axios from "axios";

export const SetBaseUrl = () => {
    const storageUser = localStorage.getItem(constants.LOCAL_STORAGE_USER)
    let base_url = BASE_URL;
    return base_url;
}

export const SetS3BaseUrl = () => {
    let base_url = S3_BASE_URL;
    return base_url;
}

const GetToken = () => {
    return localStorage.getItem("AUTH_ACCESS_TOKEN");
}

export const POST = async (url, data = null, config = null) => {
    var res = await api.post(SetBaseUrl() + url, data, config)
    return res?.data
}

export const Post_Without_Token = (url, data = null) => {
    return axiosService.post(SetBaseUrl() + url, data);
}

export const GET = async (url, params = null) => {
    //console.log(params,"params")
    var res = await api.get(SetBaseUrl() + url, {
            params},

    );
    return res?.data
}
export const GET_AUTH = async (url, params = null) => {
    let token = await GetToken()
    const headers = {
        'x-access-token': AUTH_PROJECT_TOKEN,
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
    };
    const response = await axios.get(SetAuthBaseUrl() + url, {
            headers,
            params
        });
    return response.data;
}

export const PUT = async (url, id, data = null, config = null) => {
    var res = await api.put(SetBaseUrl() + url + "/" + id, data, config)
    return res?.data
}

export const PATCH = async (url, id, data = null, config = null) => {
    // var res = await api.put(SetBaseUrl() + url + "/" + id, data, config)

    const res = await api.patch(SetBaseUrl() + url + "/" + id, data, config);

    return res?.data
}

export const DELETE = async (url, id) => {
    var res = await api.delete(SetBaseUrl() + url + "/" + id);
    return res?.data
}


