import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {EXCLUDE_NUMBER_INPUT} from "../../utils/constants";
import {FadeLoader} from "react-spinners";
import {uploadToS3} from "../../utils/helpers";
import {GetBadgeById, UpdateBadge} from "../../services/badges.service";

function EditBadge(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const[type, setType] = useState("")
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [file, setFile] = useState(null)
    const[status,setStatus] = useState()
    const [loader, setLoader] = useState(false);

    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });


    useEffect(async () => {
        let id = props.match.params.id;
        await getBadgeById(id);
    }, [])

    const getBadgeById = async (id) => {
        await GetBadgeById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    // console.log(" result.data?.type", result.data?.type)
                    setData(result.data);
                    setValue("title", result.data?.title);
                    setValue("type", result.data?.type);
                    setValue("count", result.data?.count)
                    setStatus(result.data?.status)
                    if(result.data?.image){
                        setImage(result.data?.image)
                        setIsUpload(true)
                    }
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async data => {

        if(isUploadOverWrite){
            if(file.type.startsWith('image/')){
                clearErrors('media')
            }else{
                setError('media',{message:'Invalid file type'})
                return false;
            }}

        setLoader(true)
        let imageUrl = null;

        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Image is required"})
            }

            let uploadImage = await uploadToS3(file.type,file.name,file,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            imageUrl=uploadImage;
        }else {
            imageUrl = image
        }

        let id = props.match.params.id;
        let dt = {
            title: data.title,
            count: JSON.parse(data.count),
            type: data.type,
            status: status,
            image : imageUrl
        }

        await UpdateBadge(id,dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success("success");
                history.push('/badges');
            } else {
                setLoader(false)
                toast.error("error");
            }
        }).catch((error) => {
            setLoader(false)
            if (error.status == false) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error("error");
            }
        })
    };

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
            clearErrors('profileImage')
        }

    }

    return (
        <div className="EditBg">
            <h3 className="page-heading">Edit Badge</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 3 mb)</Form.Text> </Form.Label>
                                    <input
                                        {...register('image')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        className="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.image && <Form.Text
                                        className=" validationText hasError">{errors.image.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img alt={"image"} src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        onKeyDown={(e) =>
                                            EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                            e.preventDefault()
                                        }
                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "Max length is 20"
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/,
                                                message:
                                                    "Enter full name containing alphabets only"
                                            },
                                        })}

                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className={"mb-3"} >
                                    <Form.Label className='d-block'>Select Type*</Form.Label>
                                    <Form.Select className='formselect' aria-label="type"
                                                 {...register('type', {
                                                     required: {
                                                         value: true,
                                                         message: "Type is required"
                                                     }
                                                 })}>
                                        <option value={""} disabled={true} selected={true}>Select</option>
                                        <option value={"like"}>Like</option>
                                        <option value={"comment"}>Comment</option>
                                        <option value={"subscribe"}>Subscribe</option>
                                    </Form.Select>
                                    {errors.type && <Form.Text
                                        className=" validationText hasError">{errors.type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>Count*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Specify minimum count for earning badge"
                                        id={"formSignUpName"}
                                        maxLength={50}
                                        minLength={3}
                                        onKeyDown={(e) => {
                                            const inputValue = e.target.value + e.key;
                                            const numericValue = parseInt(inputValue);

                                            // Allow numbers between 1 and 50 and prevent non-numeric input
                                            if (
                                                isNaN(numericValue) ||
                                                numericValue < 1 ||
                                                numericValue > 50
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        {...register('count', {
                                            required: {
                                                value: "required",
                                                message: "Count is required"
                                            },
                                            min: {
                                                value: 1,
                                                message: "Min value is 1"
                                            },
                                            max: {
                                                value: 50,
                                                message: "Max value is 50"
                                            },
                                        })}

                                    />
                                    {errors.price && <Form.Text
                                        className="text-muted validationText hasError">{errors.price.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className={"mb-3"} controlId="criteria">
                                    <Form.Label className='d-block'>Select Status*</Form.Label>
                                    <Form.Select className='formselect' aria-label="status"
                                                 {...register('status', {
                                                     required: {
                                                         value: "required",
                                                         message: "Status is required"
                                                     }
                                                 })} onChange={(e) => {
                                        setStatus(e.target.value);
                                    }}>

                                        <option value={true} selected={status == true}>Active</option>
                                        <option value={false} selected={status == false}>Inactive</option>
                                    </Form.Select>
                                    {errors.status && <Form.Text
                                        className=" validationText hasError">{errors.status}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col className='mt-3' xs={4} sm={4} md={4} lg={4} xl={4}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update Badge"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditBadge;