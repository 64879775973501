import React, {useEffect, useState} from 'react'
import {Col, Form, FormCheck, FormGroup, FormLabel, Row} from 'react-bootstrap'
import Select from "react-select";
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {FadeLoader} from "react-spinners";
import {GetUsers} from "../../services/users.service";
import {GetAdvertisements} from "../../services/advertisements.service";
import {AddAdAssignToCreator} from "../../services/adassigntocreator.service";
import {toast} from "react-toastify";

function AdAssignToCreators() {

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 1);
    let history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [selectSelectedUsers, setSelectSelectedUsers] = useState(false); // Change to true
    const [loader, setLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState([])
    const [advertisements, setAdvertisements] = useState([]);
    const [videoAd, setVideoAd] = useState();

    const { register, reset, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        getUsers()
        getAdvertisements();
    }, [])

    const getUsers = async () => {
        await GetUsers(null, null, null, false).then((result) => {
            if (result.status && result.data.users) {
                setUsers(result.data.users);
            }
        })
    }

    const optionsUsers = (selectedUser) => {
        let data = [];
        selectedUser.map((item)=>{
            data.push(item.value)
        });
        setSelectedUser(data);
        clearErrors('users')
    }

    const getAdvertisements = async () => {
        await GetAdvertisements(null, null, null, false).then((result) => {
            if (result.status && result.data) {
                setAdvertisements(result.data);
            }
        });
    };

    const selectedAdvertisement = async (item) => {
        let videoAd = advertisements.find((ad) => ad._id === item.value)
        setVideoAd(videoAd)
        clearErrors("advertisement");

        return videoAd;
    };

    const onSubmit = async data => {

        if (!videoAd) {
            setError("advertisement", {
                message: "Select a advertisement.",
            });
        }else {
            clearErrors("advertisement");
        }
        if (!selectAllUsers && !selectSelectedUsers && selectedUser.length === 0) {
            setError("allUsers", {
                type: "manual",
                message: "Select at least one all users or selected user from checkboxes.",
            });
        } else {
            if (!selectAllUsers && selectedUser.length === 0) {
                setError("users", {
                    type: "manual",
                    message: "Select a user.",
                });
            }else{
                // Proceed with form submission
                let dt = {
                    advertisements: videoAd,
                    users: selectedUser,
                };
                // console.log("dt ===", dt)
                setLoader(true)
                await AddAdAssignToCreator(dt).then(async (data) => {
                    setLoader(false)
                    if (data.status) {
                        console.log(data)
                        toast.success(data.message);
                        reset({
                            message: '',
                        })
                        window.location.reload()
                    } else {
                        setLoader(false)

                        toast.error(data.message);
                    }
                }).catch((error) => {
                    setLoader(false)
                    if (error.status == 401) {
                        // EmptyLocalStorage()
                        history.push('/');
                    }
                    else {
                        console.log(error);
                        return toast.error(error.response.data.message);
                    }
                })
            }

        }

    };

    const handleSelectAllUsersChange = (event) => {
        const isChecked = event.target.checked;
        if(isChecked){

            setSelectedUser([])
            setSelectAllUsers(true);
            setSelectSelectedUsers(false);
            clearErrors("allUsers");
            clearErrors("users");
            const allUsers = [];
            users.map((item)=>{
                allUsers.push(item._id)
            });
            setSelectedUser(allUsers)

        }

    };

    const handleSelectSelectedUsersChange = (event) => {
        const isChecked = event.target.checked;
        if(isChecked){
            setSelectedUser([])
            setSelectAllUsers(false);
            setSelectSelectedUsers(true);
            clearErrors("allUsers");
            clearErrors("users");
        }
    };



    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Ad Assign to Creators</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="users">
                                    <Form.Label className="d-block">
                                        Select advertisement
                                    </Form.Label>
                                    <Select
                                        options={advertisements && advertisements.map((e) => ({label: e.name,value: e._id,}))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedAdvertisement}
                                    />
                                    {errors.advertisement && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.advertisement.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <FormGroup className="checkbox-wrapper checkbox-height">
                                    <FormLabel>
                                        <FormCheck
                                            type="checkbox"
                                            checked={selectAllUsers}
                                            onChange={handleSelectAllUsersChange}
                                            label="All Users"
                                        />
                                    </FormLabel>
                                    <FormLabel>
                                        <FormCheck
                                            type="checkbox"
                                            checked={selectSelectedUsers}
                                            onChange={handleSelectSelectedUsersChange}
                                            label="Selected Users"
                                        />
                                    </FormLabel>
                                </FormGroup>
                                {errors.allUsers && (
                                    <div className="text-muted validationText hasError text-end">
                                        {errors.allUsers.message}
                                    </div>
                                )}
                            </Col>

                        {/*</Row>*/}

                        {/*<Row>*/}
                            {selectSelectedUsers && (
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Form.Group className="mb-3" controlId="users">
                                        <Form.Label className='d-block'>Select Users</Form.Label>
                                        <Select
                                            isMulti
                                            options={ users && users.map(e => ({ label: e.fullName, value: e._id}))}
                                            className="basic-multi-select"
                                            onChange={optionsUsers}
                                            classNamePrefix="select"
                                        />
                                        {errors.users && <Form.Text className="text-muted validationText hasError">{errors.users.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>

                        <Row>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme mt-3"}
                                           value={"Assign to creator"} />
                                }
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AdAssignToCreators
