import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, MenuDots, SearchIcon} from "../../components/SvgIcons";
import { Link, useHistory } from "react-router-dom"
import DataTable from "react-data-table-component";
import {customStyles, STATUS} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import CustomDataTable from "../../components/CustomDataTable";
import {AiOutlineEdit} from "react-icons/ai";
import {MdOutlineCancel,MdCheckCircleOutline} from "react-icons/md";
import {GetContents, UpdateContent} from "../../services/contents.service";
import moment from "moment";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GenerateOtp} from "../../services/ads.service";
import OTPInput from "otp-input-react";
import {FadeLoader} from "react-spinners";
import ThemeModal from "../../components/ThemeModal";
import {useForm} from "react-hook-form";

const data = [
    {
        id: 1,
        slug: "slug-1",
        value: "Value 1",
        default: "true",
    },
    {
        id: 2,
        slug: "slug-2",
        value: "Value 2",
        default: "false",
    },
    {
        id: 3,
        slug: "slug-3",
        value: "Value 3",
        default: "false",
    },
];

function Constant(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [rows, setRows] = useState([
    ]);
    const [loader, setLoader] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [editableValueId, setEditableValueId] = useState(null);
    const [editedValue, setEditedValue] = useState("");
    const [editedSlug, setEditedSlug] = useState("");
    const [height, setHeight] = useState('auto');
    const [modalLoader, setModalLoader] = useState(false);
    const [disable, setDisable] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)
    const {register, watch,setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        setEditableValueId(null)
        getContents();
    }, [keyword, page, perPage])

    const getContents = async () => {
        setLoading(true)
        await GetContents(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id || '-',
                            slug: dt.slug || '-',
                            value: dt.description || '-',
                            default: dt?.default,
                            created_at: moment(dt.created_at).fromNow(),
                            updated_at: moment(dt.updated_at).fromNow(),
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            console.log("Messge", error)
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const generateOtp=async ()=>{
        await GenerateOtp({type:"20"}).then(async (data) => {
            if (data.status) {
                setLoader(false)
                setShowAdModal(true)
                // toast.success(data.message);
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            console.log(error);
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                console.log(error);
                return toast.error(error.response.data.message);
            }
        })
    }

    const AdModal=()=>{
        const [loader, setLoader] = useState(false);
        const [OTP, setOTP] = useState("");
        const [disabled, setdisabled] = useState(true);
        const placeholder=['0','0','0','0','0','0']

        useLayoutEffect(() => {
            if (OTP.length==6){
                setdisabled(false)
            }else{
                setdisabled(true)
            }
        }, [OTP])

        const onOTPSubmit = async () =>{
            setModalLoader(true)
            setDisable(true)
            let id = editableValueId;
            let dt = {
                slug:editedSlug,
                description: editedValue,
                verification_code:OTP
            }

            await UpdateContent(id, dt).then(async (data) => {
                setModalLoader(false)
                setDisable(false)
                if (data.status) {
                    console.log(data.status,"data.status")
                    toast.success(data.message);
                    setModalLoader(false)
                    setShowAdModal(false)
                    setEditableValueId(null);
                    getContents();
                } else {
                    setModalLoader(false)
                    setDisable(false)
                    toast.error(data.message);
                }
            }).catch((error) => {
                setModalLoader(false)
                setDisable(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message);
                }
            })
        }

        const ResendOTP= async ()=>{
            generateOtp()
        }

        return(
            <div className={"ViewModalContact"}>
                <Form autoComplete="off" role="presentation" onSubmit={handleSubmit(onOTPSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="otp-form mb-3" controlId="formOTP">
                                <OTPInput  className={"otpInput"} value={OTP} onChange={setOTP}  OTPLength={6} otpType="number" placeholder={placeholder} disabled={disable}  />
                                {/*<button className="resend" onClick={ResendOTP} type='button' disabled={disable}>Resend</button>*/}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {modalLoader ? <div className="spin-loader">
                                    <FadeLoader color={"#4169e1"} height={10} />
                                </div> :
                                <input type={"submit"} className={"btn btn-green-theme w-100"}
                                       value={"Submit"} disabled={disabled} />}
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const handleEdit = (row) => {
        setEditableValueId(row.id);
        setEditedValue(row.value);
        setEditedSlug(row.slug)
        setHeight('auto')
    };

    const handleSave = () => {
        if(editedValue <= 0){
            setError('description',{ message: "Description is required"})
        }else if(editedValue?.length >= 450){
            setError('description',{ message: "Max length is 450"})
        }else{
            setLoader(true)
            generateOtp()
            clearErrors('description')
        }

        // Save edited value here using API call
        setHeight('auto')
    };

    const handleCancel = () => {
        setEditableValueId(null);
        setHeight('auto')
    };

    const valueCell = (row) => {

    const handleChange=(e)=>{
        if(e.target.value <= 0){
           setError('description',{ message: "Description is required"})
         }else if(e.target.value.length >= 450){
            setError('description',{ message: "Max length is 450"})
        }else{
           clearErrors('description')
         }
           setEditedValue(e.target.value)
           setHeight(`${e.target.scrollHeight}px`);
    }
        if (editableValueId === row.id) {
            return (
                <>
                <textarea
                    className="constant-edit"
                    type="text"
                    value={editedValue}
                    style={{ height }}
                    onChange={(e) => handleChange(e)}
                />
            {errors.description && <Form.Text
                className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                </>
            );
        }

        return row.value;
    };

    const actionCell = (row) => {
        if (editableValueId === row.id) {
            return (
                <> {loader ? <div className="table-spin-loader">
                        <FadeLoader color={"#4169e1"} height={10}  />
                    </div> :
                    <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                        <Dropdown.Item as="button"  onClick={handleSave}>Save</Dropdown.Item>
                        <Dropdown.Item as="button"  onClick={handleCancel}>Cancel</Dropdown.Item>
                    </DropdownButton>}
                </>
            );
        }

        return <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
            <Dropdown.Item as="button"  onClick={() => handleEdit(row)}>Edit</Dropdown.Item>
        </DropdownButton>
    };

    const columns = [
        {
            id: 'slug',
            name: 'Slug',
            sortable: true,
            maxWidth: '160px',
            selector: rowData => rowData.slug,
        },
        {
            id: 'description',
            name: 'Description',
            maxWidth: '240px',
            center: false,
            // selector: rowData => rowData.description,
            cell: valueCell,
        },
        {
            id: 'default',
            name: 'Default',
            maxWidth: '240px',
            center: false,
            selector: rowData => rowData.default,
        },
        {
            id: 'created_at',
            name: 'Created At',
            maxWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
        },
        {
            id: 'updated_at',
            name: 'Updated At',
            maxWidth: '140px',
            grow: false,
            center: true,
            selector: rowData => rowData.updated_at,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: actionCell,
        },
    ];

    return (
        <div className={"constantsPage"}>
            <h1 className="page-heading">Constants</h1>
            <hr />

            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#4169e1'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            <ThemeModal title={"Enter OTP"} show={showAdModal} onHide={() => setShowAdModal(false)} size={"sm"} content={<AdModal />} modalLoader={modalLoader}/>
        </div>
    );
}

export default Constant;