import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {GetAdvertisements,} from "../../services/advertisements.service";
import {GetPostById, UpdatePost} from "../../services/posts.service";
import Select from "react-select";

function DetailPost(props) {
  let history = useHistory();
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [showAdVideo, setShowAdVideo] = useState(true);
  const [showMainVideo, setShowMainVideo] = useState(false);
  const [showSkip, setShowSkip] = useState(false);

  const [advertisements, setAdvertisements] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [videoAd, setVideoAd] = useState();

  const {
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(async () => {
    let id = props.match.params.id;
    await getPostByid(id);
    await getAdvertisements();
  }, []);

  // useEffect(() => {
  //   // When "showAdVideo" is true, start a timer to hide the ad-video after 10 minutes
  //   if (showAdVideo) {
  //     const timer = setTimeout(() => {
  //       setShowAdVideo(false);
  //       setShowMainVideo(true);
  //     }, data?.advertisements?.duration); // 10 minutes in milliseconds
  //     return () => clearTimeout(timer); // Clear the timer when the component unmounts
  //   }
  // }, [showAdVideo]);

  const getPostByid = async (id) => {
    setDataLoader(true)
    await GetPostById(id, {
      is_admin: true,
    })
        .then((result) => {
          if (result.status) {
            setDataLoader(false)
            setShowSkip(result?.data?.advertisements?.skip)

            if(result?.data?.advertisements == null || Object.keys(result?.data?.advertisements).length === 0){
              setSelectedAd(null)
              setShowAdVideo(false)
              setShowMainVideo(true)
            }else{
              setSelectedAd({
                label: result?.data?.advertisements?.name,
                value: result?.data?.advertisements?._id,
              });
            }

            setData(result?.data);
          } else {
            return toast.error(result.message);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.data.message);
          }
        });
  };

  const getAdvertisements = async () => {
    await GetAdvertisements(null, null, null, false).then((result) => {
      if (result.status && result.data) {
        setAdvertisements(result.data);
      }
    });
  };

  const selectedAdvertisement = async (item) => {
    let videoAd
    if(!item){
      setSelectedAd(null);
      setVideoAd({})

    }else {
      videoAd = advertisements.find((ad) => ad._id === item.value)
      setVideoAd(videoAd)
      setSelectedAd(item);
    }
    return videoAd;

  };

  const onSubmit = async (data) => {
    setLoader(true)
    let id = props.match.params.id;
    // if (!videoAd) {
    //   setError("advertisement", {
    //     message: "Select a advertisement.",
    //   });
    // }else {
    // console.log("videoAd", videoAd)
    //   clearErrors("advertisement");
    let dt = {
      advertisements: videoAd
    }
    await UpdatePost(id,dt).then(async (data) => {
      setLoader(false)
      if (data.status) {
        toast.success("success");
        history.push('/videos');
      } else {
        setLoader(false)
        toast.error("error");
      }
    }).catch((error) => {
      setLoader(false)
      if (error?.response?.status === 401) {
        history.push('/videos');
      } else {
        return toast.error("error");
      }
    })

    // }
  };

  const handleSkip = () => {
    setShowAdVideo(false);
    setShowMainVideo(true);
  };

  return (
      <div className="EditPage">
        {/*<BreadcrumbsComponent />*/}
        <h3 className="page-heading">Video Detail</h3>
        <hr />

        <Row>
          <Form
              className="formAddUser"
              autoComplete="off"
              role="presentation"
              onSubmit={handleSubmit(onSubmit)}
          >
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                {dataLoader ? (
                    <div className="spin-loader">
                      <FadeLoader color={"#f39633"} height={10} />
                    </div>
                ) : (
                    <>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <h2>{data?.title}</h2>
                        {showAdVideo && (
                            <div className="ad-video">
                              <video
                                  poster={data?.advertisements?.thumbnail}
                                  src={data?.advertisements?.media_url}
                                  style={{ width: "100%" }}
                                  autoPlay
                                  muted
                              ></video>
                              {showSkip && (
                                  <button className="skipButton" onClick={handleSkip}>
                                    Skip
                                  </button>
                              )}
                            </div>
                        )}
                        {showMainVideo && (
                            <div className="video">
                              <video
                                  poster={data?.attachments[0]?.thumbnail}
                                  src={data?.attachments[0]?.url}
                                  controls
                                  style={{ width: "100%" }}
                              ></video>
                            </div>
                        )}
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ marginLeft: "auto" }}>
                        <Form.Group className="mb-3" controlId="users">
                          <Form.Label className="d-block">
                            Select advertisement
                          </Form.Label>
                          <Select
                              options={advertisements && advertisements.map((e) => ({label: e.name,value: e._id,}))}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={selectedAdvertisement}
                              defaultValue={selectedAd}
                              value={selectedAd}
                          />
                          {errors.advertisement && (
                              <Form.Text className="text-muted validationText hasError">
                                {errors.advertisement.message}
                              </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </>
                )}
              </Row>
              <Row>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  {loader ? (
                      <div className="spin-loader">
                        <FadeLoader color={"#f39633"} height={10} />
                      </div>
                  ) : !dataLoader ? (
                      <input
                          type="submit"
                          className="btn btn-green-theme mt-3"
                          value="Update"
                      />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Form>
        </Row>
      </div>
  );
}

export default DetailPost;
