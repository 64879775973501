import {apiUrl, AUTH_BASE_URL, AUTH_PROJECT_TOKEN, constants} from '../../utils/constants';
import {POST} from '../api.service.wrapper';
import axios from "axios";

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;


export const SetAuthBaseUrl = () => {
    return AUTH_BASE_URL;
}

const headers = {
    'x-access-token': AUTH_PROJECT_TOKEN,
};
export const SetToken = (token) => {
    if (token) localStorage.setItem(storageKey, token);
    else localStorage.removeItem(storageKey);
}
export const Login = async (email, password, authType, role, device_type, device_token) => {

    try {
        const response = await axios.post(SetAuthBaseUrl() + apiUrl.login, {email, password, authType, role, device_type, device_token},{ headers });
        await SetToken(response.data.data?.access_token);
        await SetAuthUser(JSON.stringify(response.data.data?.user));
        return response;
    }catch (e) {
        return  e
    }
}

export const GetToken = () => {
    return localStorage.getItem("AUTH_ACCESS_TOKEN");
}

export const SetAuthUser = (user_data) => {
    if (user_data) localStorage.setItem(storageUser, user_data);
    else localStorage.removeItem(storageUser);
}

export const GetAuthUser = async () => {
    return await JSON.parse(localStorage.getItem(storageUser));
}

export const GetAccessToken = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.access_token;
}

export const Logout = async (deviceType, deviceToken) => {
    try {
        let token = await GetToken();

        headers['Authorization'] = `Bearer ${token}`;
        headers['Access-Control-Allow-Origin'] = '*';

        const response = await axios.post(SetAuthBaseUrl() + apiUrl.logout, {deviceType:deviceType, deviceToken:deviceToken}, {headers});

        return response;
    }catch (e) {
        return e
    }
}

export const EmptyLocalStorage = async () => {
    await localStorage.removeItem(storageUser);
    return await localStorage.removeItem(storageKey)
}

export const GetCurrentUser = () => {
    return JSON.parse(localStorage.getItem(storageUser));
}

export const ForgetPasswordApi = async (email) => {
    const response = await POST(apiUrl.forgot_password, {email});
    return response;
}

export const VerifyOtpApi = async (otp_code, email) => {
    const response = await POST(apiUrl.verify_otp, {otp_code, email});

    return response;
}

export const VerifyLoginOtpApi = async (otp_code, email,password) => {
    const response = await POST(apiUrl.admin_verify_otp, {otp_code, email,password});
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data?.user));
    return response;
}

export const ResetPasswordApi = async (email, otp_code, password, password_confirmation) => {
    return await POST(apiUrl.reset_password, {email, otp_code, password, password_confirmation});
}

export const ChangePasswordApi = async (currentPassword, newPassword, confirmNewPassword) => {
    let token = await GetToken();

    headers['Authorization'] = `Bearer ${token}`;
    headers['Access-Control-Allow-Origin'] = '*';

    return await axios.patch(SetAuthBaseUrl() + apiUrl.change_password, {currentPassword, newPassword, confirmNewPassword},{ headers });

    // const response = await POST(apiUrl.change_password, {current_password, password, password_confirmation});
    // return response;
}

export const GetUserRole = () => {
    const user = GetAuthUser();
    let role;
    if (user && user.roles) {
        let role_id = user.roles[0].id;
        if (role_id == constants.ROLES.ROLE_ADMIN) {
            role = constants.ROLES.ROLE_ADMIN;
        } else if (role_id == constants.ROLES.ROLE_VENDOR) {
            role = constants.ROLES.ROLE_VENDOR;
        } else if (role_id == constants.ROLES.ROLE_CSA) {
            role = constants.ROLES.ROLE_CSA;
        } else {
            role = constants.ROLES.ROLE_USER;
        }
    }

    return role;
}
