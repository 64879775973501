import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {FadeLoader} from "react-spinners";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {AddPage} from "../../services/pages.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";


function AddNewPage() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {register, setValue, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {
        setLoader(true)
        let dt = {
            title: data.title,
            status: true,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())) ,
        }
        console.log(dt)
        await AddPage(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/pages');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    const onEditorStateChange=(newEditorState)=>{
        setEditorState(newEditorState)
    }

    return (
        <div className="AddPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add Page</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        {...register('title',{
                                            required: 'Title is required',
                                            minLength: {
                                                value: 3,
                                                message: 'Title must be at least 3 characters long',
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: 'Title cannot exceed 20 characters',
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            {/*<Col xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                            {/*    <Form.Group className="mb-3" controlId="slug">*/}
                            {/*        <Form.Label>Slug*</Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            {...register('slug',{*/}
                            {/*                required: 'Slug is required',*/}
                            {/*                minLength: {*/}
                            {/*                    value: 3,*/}
                            {/*                    message: 'Slug must be at least 3 characters long',*/}
                            {/*                },*/}
                            {/*                maxLength: {*/}
                            {/*                    value: 20,*/}
                            {/*                    message: 'Slug cannot exceed 20 characters',*/}
                            {/*                },*/}
                            {/*                pattern: {*/}
                            {/*                    value: /^[a-z0-9-]+$/,*/}
                            {/*                    message: 'Invalid slug format. Only lowercase letters, numbers, and hyphens are allowed.',*/}
                            {/*                }*/}
                            {/*            })}*/}
                            {/*            type="text"*/}

                            {/*        />*/}
                            {/*        {errors.slug && <Form.Text*/}
                            {/*            className=" validationText hasError">{errors.slug.message}</Form.Text>}*/}

                            {/*    </Form.Group>*/}
                            {/*</Col>*/}

                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"#f39633"} height={10}/>
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Save Page"}/>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewPage;