import {DELETE, GET} from './api.service.wrapper';
import axios from "axios";
import {GetToken} from "./auth/auth.service";
import {apiUrl, AUTH_PROJECT_TOKEN, BASE_URL} from '../utils/constants';

const headers = {
    'x-access-token': AUTH_PROJECT_TOKEN,
    'Authorization': `Bearer ${GetToken()}`,
    'Access-Control-Allow-Origin': '*',
};

export const SetAuthBaseUrl = () => {
    return BASE_URL;
}

export const GetPackages = async (keyword = '', page = '', perPage = '', is_paginate = 1, status = null) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per_page'] = perPage;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (status) {
        params['status'] = status;
    }

    params['paginate'] = is_paginate;
    const response = await GET(apiUrl.packages, params);
    return response;
}

export const GetPackageById = async (id) => {
    let response = '';
    // let params = {};
    // params['user_id'] = id;
    response = await GET(apiUrl.packages + `/${id}`);
    return response;
}

export const CreatePackage = async (data) => {
    const config = {
        headers: {
            'x-access-token': AUTH_PROJECT_TOKEN
        }
    }
    const response = axios.post(SetAuthBaseUrl() + apiUrl.packages, data,config);
    return response;
}

export const UpdatePackage = async (id, data) => {
    console.log(id,data,"api payload")
    const response = await axios.patch(SetAuthBaseUrl() + apiUrl.packages + "/" +id, data,{ headers });

    // const response = await PUT(apiUrl.user_update_id, id, data);
    return response;
}

export const DeletePackages = async (id) => {
    const response = await DELETE(apiUrl.packages, id);
    return response;
}