import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Col, FormControl, InputGroup, Row} from 'react-bootstrap'
import {toast} from "react-toastify";
import {GetPostById} from "../../services/reportedPosts.service";
import {constants, customStyles, REPORT_TYPES} from "../../utils/constants";
import Card from 'react-bootstrap/Card';
import {GetReports} from "../../services/reports.service";
import moment from "moment";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {EmptyState} from "../../components/EmptyState";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {SearchIcon} from "../../components/SvgIcons";

function ViewDetail(props) {
    const history = useHistory();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState()
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        let id = props.match.params.id;
        await getPostByid(id);
        await getReports(id, REPORT_TYPES.POST);
    }, [keyword, page, perPage]);

    const getReports = async (module_id, report_module) => {
        setLoading(true)
        await GetReports(keyword, page, perPage, 1, module_id,  report_module).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setTotalRows(result.data?.pagination?.total);
                    let counter = 1; // Initialize the counter to start from 1
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: counter++,
                            _id:dt._id || '-',
                            user_name: dt?.user?.fullName  || '-',
                            report_type: dt?.type?.name || '-',
                            report_content : (dt?.module === REPORT_TYPES.STREAMING)? dt?.post.name:dt?.post.title || '-',
                            // status: dt?.duration,
                            created_at: moment(dt?.created_at).fromNow(),

                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            }
            else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }

    const getPostByid = async (id) => {
        await GetPostById(id,{
            is_admin:true
        }).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setData(
                            {
                                _id: dt._id,
                                title: dt.title,
                                categories: dt.tags.category,
                                user: dt?.user,
                                status_value: dt?.status,
                                is_reported: dt?.is_reported,
                                thumbnail: dt?.attachments[0]?.thumbnail || constants.DEFAULTUSERIMAGE,
                                video_url: dt?.attachments[0]?.url,
                            }
                        )
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.message);
            }
        })
    }

    const formStyle = {
        display: 'inline'
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
            cellExport: rowData => rowData.id,
        },
        {
            id: 'user_name',
            name: 'User',
            center: true,
            sortable: true,
            selector: rowData => rowData.user_name,
            cellExport: rowData => rowData.user_name,
        },
        {
            id: 'report_type',
            name: 'Type',
            center: true,
            selector: rowData => rowData.report_type,
            cellExport: rowData => rowData.report_type,
        },
        {
            id: 'created_at',
            name: 'Created At',
            grow: false,
            center: true,
            selector: rowData => rowData.created_at,
            cellExport: rowData => rowData.created_at,
        },
    ];

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">View Detail</h3>
            <hr/>
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-4"}>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.DEFAULTUSERIMAGE;
                                    }}
                                    src={data?.thumbnail}
                                    style={{ objectFit: 'contain', height: '200px', width: '100%' }}
                                />
                                <Card.Body>
                                    <Card.Title>{data?.title}</Card.Title>
                                    {data && data.categories
                                        ? (
                                            <p>
                                                <strong>Categories:</strong>{' '}
                                                {data.categories.map((category, index) => (
                                                    <span key={category.id}>
                                                    {category.name}
                                                        {index < data.categories.length - 1 ? ', ' : ''}
                                                </span>
                                                ))}
                                            </p>
                                        )
                                        : ''}
                                        <p><b>Video url: </b> <a href={data?.video_url} target="_blank">{data?.title}</a></p>
                                        <p><b>Video Owner: </b> {data?.user?.fullName}</p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mb-4"}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Reports</Card.Title>
                                    <br/>
                                    <Row>
                                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                            {/*{rows && rows.length > 0 ? (*/}
                                            <InputGroup className="mb-3 search-group">
                                                <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                                                <FormControl
                                                    placeholder="Search"
                                                    aria-label="Search"
                                                    aria-describedby="Search"
                                                    maxLength={"255"}
                                                    onChange={(e) => {
                                                        (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                                    }}
                                                />
                                            </InputGroup>
                                            {/*) : ('')}*/}
                                        </Col>
                                        <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>

                                        </Col>
                                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>

                                        </Col>
                                    </Row>

                                    <div className="theme-dataGrid products-dataGrid">
                                        {rows && rows.length == 0 ? (
                                            <EmptyState/> // Render the EmptyState component when there are no records
                                        ): (
                                            <DataTableExtensions
                                                columns={columns}
                                                data={rows}
                                                filter={false}
                                                print={false}
                                                exportHeaders
                                                fileName='Reports'
                                            >
                                                <DataTable
                                                    pagination
                                                    columns={columns}
                                                    data={rows}
                                                    progressPending={loading}
                                                    customStyles={customStyles}
                                                    selectableRows={false}
                                                    striped
                                                    highlightOnHover

                                                    paginationServer
                                                    paginationTotalRows={totalRows}

                                                    onChangePage={(page) => {
                                                        setPage(page);
                                                    }}
                                                    onChangeRowsPerPage={(currentRowsPerPage) => {
                                                        setPerPage(currentRowsPerPage)
                                                    }}
                                                    progressComponent={<BarLoader color={'#f39633'} loading={loading} css={'marginTop: 10px'}
                                                                                  height={'4'} width={'100%'} />}
                                                />
                                            </DataTableExtensions>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export default ViewDetail
